export default class CopyLink {
    static init() {
        const classCopied = 'link-copied';

        document.querySelectorAll('.copy-link').forEach((elem) => {
            elem.addEventListener('click', () => {
                navigator.clipboard.writeText(document.URL);
                elem.classList.add(classCopied);
                setTimeout(() => {
                    elem.classList.remove(classCopied);
                }, 2000);
            });
        });
    }
}

if (process.env.NODE_ENV == 'development') {
    window.CopyLink = CopyLink;
}
