<template>
    <div class="popup__wrapper" :class="className">
        <div class="popup__bg" @click="closePopup" />
        <div class="popup" :class="className">
            <img src="/dist/static/images/svg/icon-close-black.svg" 
                @click="closePopup" 
                class="popup__close" 
                alt="" 
                fetchpriority="low" decoding="async" loading="lazy" />
            <div class="popup__inner">
                <h1 class="popup__title" v-if="title" v-html="title" />
                <div class="popup__content">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        title: String,
        className: String,
    });
    const emit = defineEmits(['close']);

    document.body.addEventListener('keydown', function(e) {
        if (e.key == "Escape") {
            closePopup();
        }
    });

    function closePopup() {
        emit('close');
    }
</script>
