<template>
    <div class="resources__content">
        <div class="filters">
            <h4 class="filters__title" v-if="labels.filtersTitle">
                {{ labels.filtersTitle }}
            </h4>
            <p class="filters__desc" v-if="labels.filtersDesc">
                {{ labels.filtersDesc }}
            </p>
            <SelectDropdown
                name="categories" 
                v-model="filters"
                mode="multiple" 
                icon="/dist/static/images/svg/icon-category.svg" 
                :candeselect="true"
                :placeholder="labels.placeholder"
                :options="categories" />
            <div class="filters__btn Button primary" @click="loadResults()">
                {{ labels.filterBtn }}
            </div>
        </div>
        <div class="list" ref="listElem">
            <div class="sort">
                {{ labels.sortBy }}
                <select name="sort" v-model="sort" @change="loadResults()" class="small">
                    <option :value="option.value" v-for="option in labels.sort" :key="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div class="resource" v-for="(resource, index) in resources" :key="index">
                <h4 class="resource__title">
                    {{ resource.title }}
                </h4>
                <p class="resource__desc" v-if="resource.desc">
                    {{ resource.desc }}
                </p>
                <div class="resource__bot">
                    <div class="resource__tags">
                        <div class="resource__tag" v-for="category in resource.categories" :key="category">
                            {{ category }}
                        </div>
                    </div>
                    <a :href="resource.link" target="_blank" class="Button tertiary resource__more">
                        <span class="text">
                            {{ labels.readMore }}
                        </span>
                        <svg width="47" height="10" xmlns="http://www.w3.org/2000/svg"> <g fill="#F75C5C" fill-rule="nonzero"> <path d="M.968 5.779c-.436-.04-.759-.475-.732-.989.027-.514.394-.923.833-.927l35.532-.35c.45.001.806.424.802.951-.01.53-.383.962-.834.967l-35.533.35c-.023 0-.046 0-.068-.002z"/> <path d="M34.196 9.012a.96.96 0 01-.03-1.91 2.4 2.4 0 001.093-4.348.96.96 0 111.102-1.573 4.32 4.32 0 01-1.966 7.828 1.012 1.012 0 01-.199.003z"/> <path d="M34.79 8.979a.96.96 0 01-.11-1.897l7.577-1.562-6.73-2.975a.96.96 0 01.776-1.756l9.473 4.187a.96.96 0 01-.194 1.818L35.07 8.962a.966.966 0 01-.278.017z"/> </g></svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref, onMounted } from 'vue';
    import axios from 'axios'
    import SelectDropdown from './SelectDropdown.vue';

    const props = defineProps([
        'labels',
        'categories',
        'siteHandle',
    ]);

    const listElem = ref(null);
    let loadingMore = false;
    const filters = ref([]);
    const sort = ref(props.labels.sort[0].value);
    let page = 1;
    const limit = 6;
    const resources = ref([]);
    let endOfResults = false;

    onMounted(() => {
        loadResults();

        window.addEventListener('scroll', function (event) {
            if(listElem.value && !loadingMore && !endOfResults) {
                if(listElem.value.getBoundingClientRect().bottom < window.innerHeight) {
                    loadingMore = true;
                    page++;
                    loadResults(true);
                }
            }
        }, false);
    });

    function loadResults(more) {
        if(!more) {
            page = 1;
            endOfResults = false;
        }

        const data = {
            page,
            limit,
            categories: filters.value,
            sort: sort.value,
            site: props.siteHandle,
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/get-resources', data).then((response) => {
            if(more) {
                resources.value = [...resources.value, ...response.data];

                if(response.data.length < limit) {
                    endOfResults = true;
                }

                setTimeout(() => {
                    loadingMore = false;
                }, 500);
            }
            else {
                resources.value = response.data;
            }
        });
    }
</script>