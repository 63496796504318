export const FieldsOfActivityDropdown = [
    {
        label: 'What type of job are you posting?',
        value: '',
        disabled: 'disabled'
    },
    {
        label: 'Accounting, finance and insurance',
        value: 'accountingFinanceAndInsurance'
    },
    {
        label: 'Administrative Support',
        value: 'administrativeSupport'
    },
    {
        label: 'Art, fashion and film',
        value: 'artFashionAndFilm'
    },
    {
        label: 'Communications and marketing',
        value: 'communicationsAndMarketing'
    },
    {
        label: 'Construction/Trades/Oil & Gas',
        value: 'constructionTradesOilGas'
    },
    {
        label: 'Digital Technology and Media',
        value: 'digitalTechnologyAndMedia'
    },
    {
        label: 'Education and social services',
        value: 'educationAndSocialServices'
    },
    {
        label: 'Engineering and science',
        value: 'engineeringAndScience'
    },
    {
        label: 'Health',
        value: 'health'
    },
    {
        label: 'Supervision',
        value: 'supervision'
    },
    {
        label: 'Troubleshooting',
        value: 'troubleshooting'
    },
    {
        label: 'Hotel and tourism',
        value: 'hotelAndTourism'
    },
    {
        label: 'Human resources',
        value: 'humanResources'
    },
    {
        label: 'Public protection and law',
        value: 'publicProtectionAndLaw'
    },
    {
        label: 'Sales, purchasing and customer service',
        value: 'salesPurchasingAndCustomerService'
    },
    {
        label: 'Senior management and executives',
        value: 'seniorManagementAndExecutives'
    },
    {
        label: 'Transportation and vehicles',
        value: 'transportationAndVehicles'
    },
    {
        label: 'Mining',
        value: 'mining'
    },
    {
        label: 'Diamond Drilling',
        value: 'diamondDrilling'
    },
    {
        label: 'Surface Services',
        value: 'surfaceServices'
    },
    {
        label: 'Transport',
        value: 'transport'
    },
    {
        label: 'Other',
        value: 'other'
    },
];