<template>
    <div class="multiselect__wrapper" :class="{'is-open': isOpen, 'is-error': error, 'is-empty': selectedTags.length < 1}">
        <div class="multiselect__inner">
            <Multiselect
                v-model="input"
                ref="multiselect"
                :options="options"
                :placeholder="placeholder"
                :canClear="canclear != true ? false : canclear"
                :canDeselect="candeselect"
                :classes="{ container: `multiselect ${customClass ? customClass : ''}` }"
                :searchable="searchable"
                :required="required"
                :name="name"
                :mode="mode"
                :close-on-select="false"
                :hideSelected="false"
                :nativeSupport="true"
                @open="isOpen = true"
                @close="isOpen = false"
            >
                <template v-slot:caret>
                    <img alt="" 
                        :src="alternatecaret ? '/dist/static/images/svg/dropdown-down.svg' : '/dist/static/images/svg/arrow-down-grey.svg'" 
                        :class="{'is-alternate': alternatecaret}"
                        fetchpriority="low" decoding="async" loading="lazy" />
                </template>

                <template v-slot:placeholder>
                    <div class="placeholder" @click="closeDropdown">
                        <img v-if="icon" :src="icon" class="icon" alt="" fetchpriority="low" decoding="async" loading="lazy">
                        {{ placeholder }}
                    </div>
                </template>
                <template v-slot:multiplelabel>
                    <div class="placeholder" @click="closeDropdown">
                        <img v-if="icon" :src="icon" class="icon" alt="" fetchpriority="low" decoding="async" loading="lazy">
                        {{ placeholder }}
                    </div>
                </template>
                <template v-slot:singlelabel>
                    <div class="placeholder">
                        <img v-if="icon" :src="icon" class="icon" alt="" fetchpriority="low" decoding="async" loading="lazy">
                        {{ placeholder }}
                    </div>
                </template>

                <template v-slot:option="{ option }">
                    <div class="checkbox">
                        <img alt="" src="/dist/static/images/svg/icon-check.svg" fetchpriority="low" decoding="async" loading="lazy" />
                    </div>
                    {{ option.label }}
                </template>
            </Multiselect>
        </div>
        <div class="multiselect-tags" v-if="selectedTags.length">
            <div class="multiselect-tag custom" 
                v-for="tag in selectedTags" 
                :key="tag.value"
                @click="removeElement(tag)">
                {{ tag.label }}
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref, watch } from 'vue';
    import Multiselect from '@vueform/multiselect';

    const multiselect = ref(null);

    const emit = defineEmits(['update:modelValue']);
    const props = defineProps([
        'modelValue',
        'name',
        'options',
        'customClass',
        'searchable',
        'required',
        'candeselect',
        'canclear',
        'mode',
        'icon',
        'placeholder',
        'alternatecaret',
        'error',
    ]);

    const input = computed({
        get: () => props.modelValue,
        set: value => {
            emit('update:modelValue', value);
        },
    });

    const selectedTags = computed(() => {
        return props.options.filter(option => {
            if(input.value != undefined) {
                return JSON.parse(JSON.stringify(input.value)).includes(option.value);
            }
        });
    });

    function removeElement(element) {
        multiselect.value.deselect(element);
    }

    //Little hack to enable the closing on dropdown by clicking on the label thing
    const isOpen = ref(false);
    let openingTimeout = false;
    watch(isOpen, (value) => {
        if(value) {
            openingTimeout = true;
            setTimeout(() => {
                openingTimeout = false;
            }, 500);
        }
    });
    function closeDropdown() {
        if(!openingTimeout) {
            if(!isOpen.value) {
                multiselect.value.open();
            }
            else {
                multiselect.value.close();
            }
        }
    }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>