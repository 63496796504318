export const JobTypesDropdown = [
    {
        label: 'Part-time',
        value: 'partTime'
    },
    {
        label: 'Full-time',
        value: 'fullTime',
    },
    {
        label: 'Maternity replacement',
        value: 'maternityReplacement'
    },
];