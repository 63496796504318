<template>
    <h1 class="title">
        {{ labels['Build my resume'] }}
    </h1>
    <p class="subtitle">
        {{ labels['BuildResumeParagraph'] }}
    </p>
    <form class="main-content" @submit.prevent="submitForm(activeStep)">
        <div class="step step-1" :class="{'is-active': activeStep == 1, 'is-disabled': activeStep < 1}">
            <h4 class="step__title" v-html="labels['Step1']" />
            <div class="step__inner">
                <div class="input-double">
                    <CompanyInput id="firstName2" 
                        :labelText="labels['Portal:FirstName']"
                        type="text"
                        v-model="v$Step1.firstName.$model"
                        :error="v$Step1.firstName.$errors.length" />
                    <CompanyInput id="lastName2" 
                        :labelText="labels['Portal:LastName']"
                        type="text"
                        v-model="v$Step1.lastName.$model"
                        :error="v$Step1.lastName.$errors.length" />
                </div>
                <div class="input-double">
                    <CompanyInput id="contactEmail" 
                        :labelText="labels['Email address*']"
                        type="email"
                        v-model="v$Step1.contactEmail.$model"
                        :error="v$Step1.contactEmail.$errors.length" />
                    <CompanyInput id="phone" 
                        :labelText="labels['Phone number*']"
                        type="tel"
                        v-model="v$Step1.phone.$model"
                        :error="v$Step1.phone.$errors.length" />
                </div>
                <div class="input-double">
                    <SelectDropdown
                        name="languages" 
                        v-model="v$Step1.langModel.$model"
                        mode="multiple" 
                        :error="v$Step1.langModel.$errors.length"
                        :candeselect="true"
                        :placeholder="labels['Select a spoken language']"
                        :options="languagesList" />
                    <CompanyInput id="location" 
                        :labelText="labels['Location']"
                        :placeholderText="labels['Location']"
                        type="select"
                        v-model="v$Step1.location.$model"
                        :error="v$Step1.location.$errors.length"
                        :options="citiesTranslated" />
                </div>
            </div>
            <Button :text="labels['Save and continue']" @click="submitForm(1)" />
        </div>
        <div class="step step-2" :class="{'is-active': activeStep == 2, 'is-disabled': activeStep < 2}">
            <h4 class="step__title" v-html="labels['Step2']" />
            <div class="step__inner">
                <p class="fake-checkbox skip" 
                    v-if="(experiencesModel.length == 1 && experiencesModel[0].editing == true && experiencesModel[0].title == '') || skipExperiencesModel"
                    :class="{'is-selected': skipExperiencesModel}" 
                    @click="skipExperiencesModel = !skipExperiencesModel">
                    <span class="fake-checkbox__checkbox" />{{ labels['Skip this section and proceed to the "Informal Experience" section'] }}
                </p>
                <template v-if="!skipExperiencesModel">
                    <div class="repeater" 
                        :class="{'is-editing': experience.editing}"
                        v-for="(experience, index) in experiencesModel" 
                        :key="index">
                        <div class="infos" v-if="!experience.editing">
                            <span class="title">
                                {{ experience.title }} - {{ experience.company }}
                            </span>
                            <span class="type" v-html="labels[experience.type]" />
                            <span class="date">
                                {{ `${experience.startMonth}/${experience.startYear}` }}
                                -
                                {{ experience.currentlyWorking ? labels['Present'] : `${experience.endMonth}/${experience.endYear}` }}
                            </span>
                        </div>
                        <div class="actions" v-if="!experience.editing">
                            <span class="edit" @click="experiencesModel[index].editing = true">
                                <img src="/dist/static/images/svg/edit.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
                                {{ labels['Edit'] }}
                            </span>
                            <span class="delete" @click="deleteExperience(index)">
                                <img src="/dist/static/images/svg/delete.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
                                {{ labels['Delete'] }}
                            </span>
                        </div>
                        <div class="edit" v-else>
                            <strong v-html="labels['Add an experience']" />
                            <div class="input-double">
                                <CompanyInput :placeholderText="labels['Occupation / Title*']"
                                    type="text"
                                    v-model="experience.title"
                                    :error="v$Step2.experiencesModel.$each.$response?.$errors[index].title.length" />
                                <CompanyInput :labelText="labels['Type of job']"
                                    type="select"
                                    v-model="experience.type"
                                    :error="v$Step2.experiencesModel.$each.$response?.$errors[index].type.length"
                                    :options="jobTypes" />
                            </div>
                            <div class="input-double">
                                <CompanyInput :labelText="labels['Company / Business name*']"
                                    :placeholderText="labels['Name']"
                                    type="text"
                                    v-model="experience.company"
                                    :error="v$Step2.experiencesModel.$each.$response?.$errors[index].company.length" />
                                <CompanyInput :labelText="labels['City*']"
                                    :placeholderText="labels['Location']"
                                    type="text"
                                    v-model="experience.city"
                                    :error="v$Step2.experiencesModel.$each.$response?.$errors[index].city.length" />
                            </div>
                            <strong v-html="labels['Time period']" />
                            <div class="time">
                                <CompanyInput type="select"
                                    v-model="experience.startMonth"
                                    :error="v$Step2.experiencesModel.$each.$response?.$errors[index].startMonth.length"
                                    :options="getSpecificMonths(true, experience.startYear, experience.endMonth, experience.endYear, labels['Select start month'])" />
                                <CompanyInput type="select"
                                    v-model="experience.startYear"
                                    :error="v$Step2.experiencesModel.$each.$response?.$errors[index].startYear.length"
                                    :options="getSpecificYears(true, experience.startMonth, experience.endYear, labels['Select start year'])" />
                                <span class="spacer" />
                                <CompanyInput type="select"
                                    :readonly="experience.currentlyWorking"
                                    v-model="experience.endMonth"
                                    :error="v$Step2.experiencesModel.$each.$response?.$errors[index].endMonth.length"
                                    :options="getSpecificMonths(false, experience.endYear, experience.startMonth, experience.startYear, labels['Select end month'])" />
                                <CompanyInput type="select"
                                    :readonly="experience.currentlyWorking"
                                    v-model="experience.endYear"
                                    :error="v$Step2.experiencesModel.$each.$response?.$errors[index].endYear.length"
                                    :options="getSpecificYears(false, experience.endMonth, experience.startYear, labels['Select end year'])" />
                            </div>
                            <p class="fake-checkbox" 
                                :class="{'is-selected': experience.currentlyWorking}" 
                                @click="experience.currentlyWorking = !experience.currentlyWorking">
                                <span class="fake-checkbox__checkbox" />{{ labels['I currently work here'] }}
                            </p>
                            <CompanyInput :labelText="labels['Job Description / Responsibilities*']"
                                type="textarea"
                                className="title-above"
                                :placeholderText="labels['Write here...']"
                                v-model="experience.description"
                                :error="v$Step2.experiencesModel.$each.$response?.$errors[index].description.length" />
                            <div class="buttons">
                                <Button v-if="!experience.template" :text="labels['Cancel']" @click="resetExperience(index)" className="primary cancel" />
                                <span v-else />
                                <Button :text="labels['Save']" @click="saveExperience(index)" />
                            </div>
                        </div>
                    </div>
                    <div class="repeater-add" 
                        @click="addEmptyExperience(false)"
                        v-if="!experiencesModel.some(experience => experience.editing)">
                        <span />
                        {{ labels['Add an experience'] }}
                    </div>
                </template>
                <CompanyInput id="informalexperience" 
                    :labelText="labels['Informal Experience (facultative)']"
                    type="textarea"
                    className="title-above"
                    :placeholderText="labels['Write here...']"
                    v-model="v$Step2.informalExperiencesModel.$model"
                    :error="v$Step2.informalExperiencesModel.$errors.length" />
            </div>
            <Button :text="labels['Save and continue']" @click="submitForm(2)" />
        </div>
        <div class="step step-3" :class="{'is-active': activeStep == 3, 'is-disabled': activeStep < 3}">
            <h4 class="step__title" v-html="labels['Step3']" />
            <div class="step__inner">
                <p class="fake-checkbox skip" 
                    v-if="(educationModel.length == 1 && educationModel[0].editing == true && educationModel[0].title == '') || skipEducationModel"
                    :class="{'is-selected': skipEducationModel}" 
                    @click="skipEducationModel = !skipEducationModel">
                    <span class="fake-checkbox__checkbox" />{{ labels['Skip this section and proceed to the "Other informations" section'] }}
                </p>
                <template v-if="!skipEducationModel">
                    <div class="repeater" 
                        :class="{'is-editing': education.editing}"
                        v-for="(education, index) in educationModel" 
                        :key="index">
                        <div class="infos" v-if="!education.editing">
                            <span class="title">
                                {{ education.title }} - {{ education.institution }}
                            </span>
                            <span class="type" v-html="education.city" />
                            <span class="date">
                                {{ `${education.startMonth}/${education.startYear}` }}
                                -
                                {{ education.currentlyStudying ? labels['Present'] : `${education.endMonth}/${education.endYear}` }}
                            </span>
                        </div>
                        <div class="actions" v-if="!education.editing">
                            <span class="edit" @click="educationModel[index].editing = true">
                                <img src="/dist/static/images/svg/edit.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
                                {{ labels['Edit'] }}
                            </span>
                            <span class="delete" @click="deleteEducation(index)">
                                <img src="/dist/static/images/svg/delete.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
                                {{ labels['Delete'] }}
                            </span>
                        </div>
                        <div class="edit" v-else>
                            <strong v-html="labels['Add an education']" />
                            <div class="input-double">
                                <CompanyInput :placeholderText="labels['Education name*']"
                                    type="text"
                                    v-model="education.title"
                                    :error="v$Step3.educationModel.$each.$response?.$errors[index].title.length" />
                                <CompanyInput :labelText="labels['Institution*']"
                                    :placeholderText="labels['Name']"
                                    type="text"
                                    v-model="education.institution"
                                    :error="v$Step3.educationModel.$each.$response?.$errors[index].institution.length" />
                            </div>
                            <div class="input-double">
                                <CompanyInput :labelText="labels['Location*']"
                                    :placeholderText="labels['Location']"
                                    type="text"
                                    v-model="education.city"
                                    :error="v$Step3.educationModel.$each.$response?.$errors[index].city.length" />
                            </div>
                            <strong v-html="labels['Time period']" />
                            <div class="time">
                                <CompanyInput type="select"
                                    v-model="education.startMonth"
                                    :error="v$Step3.educationModel.$each.$response?.$errors[index].startMonth.length"
                                    :options="getSpecificMonths(true, education.startYear, education.endMonth, education.endYear, labels['Select start month'])" />
                                <CompanyInput type="select"
                                    v-model="education.startYear"
                                    :error="v$Step3.educationModel.$each.$response?.$errors[index].startYear.length"
                                    :options="getSpecificYears(true, education.startMonth, education.endYear, labels['Select start year'])" />
                                <span class="spacer" />
                                <CompanyInput type="select"
                                    :readonly="education.currentlyStudying"
                                    v-model="education.endMonth"
                                    :error="v$Step3.educationModel.$each.$response?.$errors[index].endMonth.length"
                                    :options="getSpecificMonths(false, education.endYear, education.startMonth, education.startYear, labels['Select end month'])" />
                                <CompanyInput type="select"
                                    :readonly="education.currentlyStudying"
                                    v-model="education.endYear"
                                    :error="v$Step3.educationModel.$each.$response?.$errors[index].endYear.length"
                                    :options="getSpecificYears(false, education.endMonth, education.startYear, labels['Select end year'])" />
                            </div>
                            <p class="fake-checkbox" 
                                :class="{'is-selected': education.currentlyStudying}" 
                                @click="education.currentlyStudying = !education.currentlyStudying">
                                <span class="fake-checkbox__checkbox" />{{ labels['I currently study here'] }}
                            </p>
                            <div class="buttons">
                                <Button v-if="!education.template" :text="labels['Cancel']" @click="resetEducation(index)" className="primary cancel" />
                                <span v-else />
                                <Button :text="labels['Save']" @click="saveEducation(index)" />
                            </div>
                        </div>
                    </div>
                    <div class="repeater-add" 
                        @click="addEmptyEducation(false)"
                        v-if="!educationModel.some(education => education.editing)">
                        <span />
                        {{ labels['Add an education'] }}
                    </div>
                </template>
                <CompanyInput id="informaleducation" 
                    :labelText="labels['Informal education or explainations (facultative)']"
                    type="textarea"
                    className="title-above"
                    :placeholderText="labels['Write here...']"
                    v-model="v$Step3.informalEducationModel.$model"
                    :error="v$Step3.informalEducationModel.$errors.length" />
            </div>
            <Button :text="labels['Save and continue']" @click="submitForm(3)" />
        </div>
        <div class="step step-4" :class="{'is-active': activeStep == 4, 'is-disabled': activeStep < 4}">
            <h4 class="step__title" v-html="labels['Step4']" />
            <div class="step__inner">
                <p class="fake-checkbox skip" 
                    :class="{'is-selected': skipInformationsModel}" 
                    @click="skipInformationsModel = !skipInformationsModel">
                    <span class="fake-checkbox__checkbox" />{{ labels['Nothing to add (profile complete)'] }}
                </p>
                <template v-if="!skipInformationsModel">
                    <div class="repeater is-editing">
                        <div class="edit">
                            <CompanyInput id="otherInformations" 
                                :labelText="labels['Description']"
                                type="textarea"
                                className="title-above"
                                :placeholderText="labels['Write here...']"
                                v-model="otherInformationsModel" />
                            <div class="buttons">
                                <Button :text="labels['Cancel']" @click="skipInformationsModel = true" className="primary cancel" />
                                <Button :text="labels['Save']" @click="submitForm(4)" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <Button :className="`primary full ${generatingPdf ? 'is-disabled' : ''}`"
                :text="labels['Save and generate my resume']" 
                @click="submitForm(5)" />
        </div>
    </form>
    <div class="resume-ready" v-if="((oldResume && activeStep == 4) || (newResume && activeStep == 5)) && !resumeEdited">
        <h1 class="title" v-html="labels['Your resume is ready to share!']" />
        <p v-html="labels['Your resume is complete. It was automatically added to your profile and is already viewable by recruiters.']" />
        <Button :text="labels['Download my resume']" 
            target="_blank"
            :link="oldResume?.url" />
        <div class="edit" v-html="labels['or edit it']" @click="editResume" />
    </div>
    <Teleport to="body">
        <Transition>
            <Notification v-if="notifSuccessOpen" 
                :title="labels['ProfileSavecSucess']"
                @close="notifSuccessOpen = false" />
        </Transition>
        <Transition>
            <Notification v-if="notifErrorOpen" 
                :title="labels['SaveError']" 
                type="error"
                @close="notifErrorOpen = false" />
        </Transition>
    </Teleport>
</template>

<script setup>
    import { computed, ref, nextTick, onMounted, watch } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import { required, helpers, alpha, email, minLength, requiredIf } from '@vuelidate/validators';
    import axios from 'axios';
    import qs from 'qs';
    import { CitiesDropdown } from '../components/CitiesDropdown.js';
    import CompanyInput from './CompanyInput.vue';
    import SelectDropdown from './SelectDropdown.vue';
    import Notification from './Notification.vue';
    import Button from './Button.vue';
    import cloneDeep from 'lodash/cloneDeep';

    const props = defineProps([
        'labels',
        'userId',
        'firstName',
        'lastName',
        'contactEmail',
        'accountEmail',
        'phoneNumber',
        'spokenLanguages',
        'location',
        'siteHandle',
        'skipExperiences',
        'experiences',
        'informalExperience',
        'skipEducation',
        'education',
        'informalEducation',
        'skipInformations',
        'otherInformations',
        'oldResume',
        'oldResumeScreenshot',
    ]);

    const errorMessage = ref('');
    const notifSuccessOpen = ref(false);
    const notifErrorOpen = ref(false);
    const activeStep = ref(1);
    const totalSteps = 4;
    let savedResumeId = cloneDeep(props.oldResume?.id);
    let savedResumeScreenshotId = cloneDeep(props.oldResumeScreenshot?.id);
    const generatingPdf = ref(false);
    const resumeEdited = ref(false);
    const newResume = ref(null);

    const citiesTranslated = computed(() => {
        return CitiesDropdown.map(function (city){
            city.label = props.labels[city.label];
            return city;
        });
    });
    const languagesList = [
        {
            value: 'en',
            label: props.labels['English']
        },
        {
            value: 'fr',
            label: props.labels['French']
        },
        {
            value: 'iu',
            label: props.labels['ᐃᓄᒃᑎᑐᑦ']
        },
    ];
    const jobTypes = [
        {
            value: 'fullTime',
            label: props.labels['Full-time']
        },
        {
            value: 'apprenticeship',
            label: props.labels['Apprenticeship']
        },
        {
            value: 'internship',
            label: props.labels['Internship']
        },
    ];
    const months = [
        {
            value: "01",
            label: props.labels['January'],
        },
        {
            value: "02",
            label: props.labels['February'],
        },
        {
            value: "03",
            label: props.labels['March'],
        },
        {
            value: "04",
            label: props.labels['April'],
        },
        {
            value: "05",
            label: props.labels['May'],
        },
        {
            value: "06",
            label: props.labels['June'],
        },
        {
            value: "07",
            label: props.labels['July'],
        },
        {
            value: "08",
            label: props.labels['August'],
        },
        {
            value: "09",
            label: props.labels['September'],
        },
        {
            value: "10",
            label: props.labels['October'],
        },
        {
            value: "11",
            label: props.labels['November'],
        },
        {
            value: "12",
            label: props.labels['December'],
        },
    ];

    //Form and validation stuff
    const firstName = ref(props.firstName);
    const lastName = ref(props.lastName);
    const contactEmail = ref(props.contactEmail || props.accountEmail);
    const phone = ref(props.phoneNumber);
    const langModel = ref(props.spokenLanguages);
    const location = ref(props.location);
    const skipExperiencesModel = ref(props.skipExperiences);
    const experiencesModel = ref(props.experiences);
    //cloneDeep is used only for when we need to cancel edits on specific experiences
    let experiencesModelClone = cloneDeep(props.experiences);
    const informalExperiencesModel = ref(props.informalExperience);
    const skipEducationModel = ref(props.skipEducation);
    const educationModel = ref(props.education);
    let educationModelClone = cloneDeep(props.education);
    const informalEducationModel = ref(props.informalEducation);
    const skipInformationsModel = ref(props.skipInformations);
    const otherInformationsModel = ref(props.otherInformations);
    
    //Validations for main form
    const isPhone = (value) => helpers.regex(/^\(\d{3}\) \d{3}-\d{4}/);
    const rulesStep1 = computed(() => {
        return {
            firstName: {
                required, alpha
            },
            lastName: {
                required, alpha
            },
            contactEmail: {
                required, email
            },
            phone: {
                required, isPhone, minLength: minLength(14)
            },
            langModel: {
                required, minLength: minLength(1)
            },
            location: {
                required,
            },
        }
    });
    const v$Step1 = useVuelidate(rulesStep1, {firstName, lastName, contactEmail, phone, langModel, location}, {
        $autoDirty: true,
        $lazy: true
    });

    const skipExperiencesValidator = (value) => {
        if(value) {
            return true;
        }

        return experiencesModel.value.length;
    };
    const rulesStep2 = computed(() => {
        return {
            skipExperiencesModel: {
                skipExperiencesValidator
            },
            experiencesModel: {
                required: requiredIf(!skipExperiencesModel.value),
                $each: helpers.forEach({
                    title: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    type: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    company: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    city: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    startMonth: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    startYear: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    endMonth: {
                        required: requiredIf((value, parentVm) => !parentVm.currentlyWorking && !skipExperiencesModel.value),
                    },
                    endYear: {
                        required: requiredIf((value, parentVm) => !parentVm.currentlyWorking && !skipExperiencesModel.value),
                    },
                    currentlyWorking: {

                    },
                    description: {
                        required: requiredIf(!skipExperiencesModel.value),
                    }
                }),
            },
            informalExperiencesModel: {

            },
        }
    });
    const v$Step2 = useVuelidate(rulesStep2, {skipExperiencesModel, experiencesModel, informalExperiencesModel}, {
        $autoDirty: true,
        $lazy: true
    });

    const skipEducationValidator = (value) => {
        if(value) {
            return true;
        }

        return educationModel.value.length;
    };
    const rulesStep3 = computed(() => {
        return {
            skipEducationModel: {
                skipEducationValidator
            },
            educationModel: {
                required: requiredIf(!skipEducationModel.value),
                $each: helpers.forEach({
                    title: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    institution: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    city: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    startMonth: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    startYear: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    endMonth: {
                        required: requiredIf((value, parentVm) => !parentVm.currentlyStudying && !skipEducationModel.value),
                    },
                    endYear: {
                        required: requiredIf((value, parentVm) => !parentVm.currentlyStudying && !skipEducationModel.value),
                    },
                    currentlyStudying: {

                    }
                }),
            },
            informalEducationModel: {

            },
        }
    });
    const v$Step3 = useVuelidate(rulesStep3, {skipEducationModel, educationModel, informalEducationModel}, {
        $autoDirty: true,
        $lazy: true
    });

    const v$Step4 = useVuelidate({}, {}, {
        $autoDirty: true,
        $lazy: true
    });


    const rulesStep5 = computed(() => {
        return {
            firstName: {
                required, alpha
            },
            lastName: {
                required, alpha
            },
            contactEmail: {
                required, email
            },
            phone: {
                required, isPhone, minLength: minLength(14)
            },
            langModel: {
                required
            },
            location: {
                required,
            },
            skipExperiencesModel: {
                skipExperiencesValidator
            },
            experiencesModel: {
                required: requiredIf(!skipExperiencesModel.value),
                $each: helpers.forEach({
                    title: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    type: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    company: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    city: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    startMonth: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    startYear: {
                        required: requiredIf(!skipExperiencesModel.value),
                    },
                    endMonth: {
                        required: requiredIf((value, parentVm) => !parentVm.currentlyWorking && !skipExperiencesModel.value),
                    },
                    endYear: {
                        required: requiredIf((value, parentVm) => !parentVm.currentlyWorking && !skipExperiencesModel.value),
                    },
                    currentlyWorking: {

                    },
                    description: {
                        required: requiredIf(!skipExperiencesModel.value),
                    }
                }),
            },
            informalExperiencesModel: {

            },
            skipEducationModel: {
                skipEducationValidator
            },
            educationModel: {
                required: requiredIf(!skipEducationModel.value),
                $each: helpers.forEach({
                    title: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    institution: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    city: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    startMonth: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    startYear: {
                        required: requiredIf(!skipEducationModel.value),
                    },
                    endMonth: {
                        required: requiredIf((value, parentVm) => !parentVm.currentlyStudying && !skipEducationModel.value),
                    },
                    endYear: {
                        required: requiredIf((value, parentVm) => !parentVm.currentlyStudying && !skipEducationModel.value),
                    },
                    currentlyStudying: {

                    }
                }),
            },
            informalEducationModel: {

            },
        }
    });
    const v$Step5 = useVuelidate(rulesStep5, {firstName, lastName, contactEmail, phone, langModel, location, skipExperiencesModel, experiencesModel, informalExperiencesModel, skipEducationModel, educationModel, informalEducationModel}, {
        $autoDirty: true,
        $lazy: true
    });

    //On mounted, validate through each step, and check which step we're currently on
    onMounted(() => {
        for(let i = 1; i < totalSteps; i++){
            try {
                const stepValidator = eval('v$Step' + i);
                stepValidator.value.$validate();
                if (!stepValidator.value.$error) {
                    activeStep.value++;
                }
                else {
                    nextTick(() => {
                        //Would need something like this to prevent the fields in error state by default... But not working
                        //stepValidator.value.$reset();
                    });
                    break;
                }
            }
            catch {
                break;
            }
        }
    });

    async function submitForm(step) {
        if(step == 5) {
            generatingPdf.value = true;
        }

        //Each form section is validated and saved independently
        const stepValidator = eval('v$Step' + step);
        stepValidator.value.$validate();

        if (!stepValidator.value.$error) {
            
            const data = {
                action: 'users/save-user',
                userId: props.userId,
                fields: {},
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            if(step == 1 || step == 5) {
                data['firstName'] = firstName.value;
                data['lastName'] = lastName.value;
                data['fields']['contactEmail'] = contactEmail.value;
                data['fields']['phoneNumber'] = phone.value;
                data['fields']['spokenLanguages'] = langModel.value;
                data['fields']['location'] = location.value;
            }
            else if(step == 2 || step == 5) {
                //Matrix fields atre tricky to submit
                const experiencesObj = {
                    blocks: {},
                    sortOrder: [],
                };
                experiencesModel.value.forEach((experience, index) => {
                    if(!experience.template) {
                        experiencesObj['sortOrder'].push(`new${index}`);
                        const experienceObj = {
                            type: 'experience',
                            enabled: 1,
                            fields: {
                                occupation: experience.title,
                                typeOfJob: experience.type,
                                companyName: experience.company,
                                city: experience.city,
                                startDate: new Date(`${experience.startYear}-${experience.startMonth}-15`),
                                endDate: experience.endYear && experience.endMonth ? new Date(`${experience.endYear}-${experience.endMonth}-15`) : null,
                                currentlyWorking: +experience.currentlyWorking,
                                description: experience.description,
                            },
                        }
                        experiencesObj['blocks'][`new${index}`] = experienceObj;
                    }
                });

                data['fields']['skipExperiences'] = +skipExperiencesModel.value;
                data['fields']['informalExperiences'] = informalExperiencesModel.value;
                data['fields']['experiences'] = experiencesObj;
            }
            else if(step == 3 || step == 5) {
                //Matrix fields atre tricky to submit
                const educationsObj = {
                    blocks: {},
                    sortOrder: [],
                };
                educationModel.value.forEach((education, index) => {
                    if(!education.template) {
                        educationsObj['sortOrder'].push(`new${index}`);
                        const educationObj = {
                            type: 'education',
                            enabled: 1,
                            fields: {
                                education: education.title,
                                institution: education.institution,
                                location: education.city,
                                startDate: new Date(`${education.startYear}-${education.startMonth}-15`),
                                endDate: education.endYear && education.endMonth ? new Date(`${education.endYear}-${education.endMonth}-15`) : null,
                                currentlyStudying: +education.currentlyStudying
                            },
                        }
                        educationsObj['blocks'][`new${index}`] = educationObj;
                    }
                });

                data['fields']['skipEeducation'] = +skipEducationModel.value;
                data['fields']['informalEducation'] = informalEducationModel.value;
                data['fields']['education'] = educationsObj;
            }
            else if(step == 4 || step == 5){
                data['fields']['skipOtherInformations'] = +skipInformationsModel.value;
                data['fields']['otherInformations'] = otherInformationsModel.value;
            }

            await axios.post('/', qs.stringify(data)).then(async (response) => {
                if(!response.data.errors) {
                    errorMessage.value = '';
                    
                    if(step != 5) {
                        notifSuccessOpen.value = true;
                        resumeEdited.value = true;
                    }

                    if(step >= activeStep.value && activeStep.value < 5) {
                        activeStep.value++;
                    }
                    //If there's a next step
                    if(activeStep.value <= totalSteps) {
                        document.querySelector(`.step-${activeStep.value}`).scrollIntoView();
                    }
                    //If resume is complete and we need to generate it
                    else if(step == 5) {
                        await axios.get(`/generate-resume-pdf?siteHandle=${props.siteHandle}`).then(async (response) => {
                            saveGeneratedResume(response.data);
                        }).catch((error) => {
                            errorMessage.value = error;
                            notifErrorOpen.value = true;
                            generatingPdf.value = false;
                        });
                    }
                    else {
                        document.querySelector('.step-4 .Button.full').scrollIntoView();
                    }
                }
                else {
                    errorMessage.value = error;
                    notifErrorOpen.value = true;
                    generatingPdf.value = false;
                }
            }).catch((error) => {
                errorMessage.value = error;
                notifErrorOpen.value = true;
                generatingPdf.value = false;
            });
        }
        else {
            notifErrorOpen.value = true;
            generatingPdf.value = false;

            nextTick(() => {	
                document.querySelector('.is-error').scrollIntoView();
            });
        }
    }
    //If image is true, we're adding/replacing the CV image, not the pdf
    async function saveGeneratedResume(data, image) {
        const assetData = new FormData();
        assetData.append(window.csrfTokenName, window.csrfTokenValue);

        //First we need to convert base64-encoded resume to a blob
        const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }
        const blob = b64toBlob(data, !image ? 'application/pdf' : 'image/png');

        //Overwrite existing resume if already exists
        if((!image && savedResumeId) || (image && savedResumeScreenshotId)) {
            assetData.append('replaceFile', blob, `CV-${props.firstName}_${props.lastName}.${!image ? 'pdf' : 'png'}`);
            assetData.append('action', 'assets/replace-file');
            assetData.append('targetFilename', `CV-${props.firstName}_${props.lastName}.${!image ? 'pdf' : 'png'}`);
            assetData.append('assetId', !image ? savedResumeId : savedResumeScreenshotId);
        }
        else {
            assetData.append('assets-upload', blob, `CV-${props.firstName}_${props.lastName}.${!image ? 'pdf' : 'png'}`);
            assetData.append('action', 'assets/upload');
            assetData.append('folderId', 4);
        }
        axios.post('/', assetData).then(async (response) => {
            if(!image) {
                savedResumeId = response.data.assetId;
            }
            else {
                savedResumeScreenshotId = response.data.assetId;
            }
            //Resume is uploaded, then save it to profile
            const data = {
                action: 'users/save-user',
                userId: props.userId,
                fields: {
                    
                }
            };
            data['fields'][!image ? 'resume' : 'resumeScreenshot'] = [response.data.assetId];
            data[window.csrfTokenName] = window.csrfTokenValue;

            await axios.post('/', qs.stringify(data)).then(async (response) => {
                if(!image) {
                    await axios.get('/get-resume-link').then(async (response) => {
                        newResume.value = response.data;
                        resumeEdited.value = false;
                        notifSuccessOpen.value = true;
                        generatingPdf.value = false;

                        //All required stuff is done, now we generate & save the cv image in the background
                        await axios.get(`/generate-resume-image?siteHandle=${props.siteHandle}`).then(async (response) => {
                            saveGeneratedResume(response.data, true);
                        });

                        nextTick(() => {
                            document.querySelector('.resume-ready').scrollIntoView();
                        });
                    }).catch((error) => {
                        errorMessage.value = error;
                        notifErrorOpen.value = true;
                        generatingPdf.value = false;
                    });
                }
            }).catch((error) => {
                errorMessage.value = error;
                notifErrorOpen.value = true;
                generatingPdf.value = false;
            });
        }).catch((error) => {
            errorMessage.value = error;
            notifErrorOpen.value = true;
            generatingPdf.value = false;
        });
    }

    //Stuff to manage experiences and education
    function deleteExperience(index) {
        experiencesModel.value.splice(index, 1);
        experiencesModelClone = cloneDeep(experiencesModel.value);
    }
    function deleteEducation(index) {
        educationModel.value.splice(index, 1);
        educationModelClone = cloneDeep(educationModel.value);
    }
    function resetExperience(index) {
        //If current experience is an existing one
        if(experiencesModelClone[index]) {
            experiencesModel.value[index] = experiencesModelClone[index];
        }
        //If current experience is a new one
        else {
            experiencesModel.value.length = index;
        }
    }
    function resetEducation(index) {
        //If current experience is an existing one
        if(educationModelClone[index]) {
            educationModel.value[index] = educationModelClone[index];
        }
        //If current experience is a new one
        else {
            educationModel.value.length = index;
        }
    }
    function saveExperience(index) {
        let hasErrors = false;
        //Loop through validation object to check if any errors 
        Object.keys(v$Step2.value.experiencesModel.$each.$response?.$errors[index]).forEach((key) => {
            if(v$Step2.value.experiencesModel.$each.$response?.$errors[index][key].length > 0) {
                hasErrors = true;
            }
        });
        if(!hasErrors) {
            experiencesModel.value[index].editing = false;
            experiencesModel.value[index].template = false;
            experiencesModelClone[index] = cloneDeep(experiencesModel.value[index]);
        }
        else {
            notifErrorOpen.value = true;
        }
    }
    function saveEducation(index) {
        let hasErrors = false;
        //Loop through validation object to check if any errors 
        Object.keys(v$Step3.value.educationModel.$each.$response?.$errors[index]).forEach((key) => {
            if(v$Step3.value.educationModel.$each.$response?.$errors[index][key].length > 0) {
                hasErrors = true;
            }
        });
        if(!hasErrors) {
            educationModel.value[index].editing = false;
            educationModel.value[index].template = false;
            educationModelClone[index] = cloneDeep(educationModel.value[index]);
        }
        else {
            notifErrorOpen.value = true;
        }
    }
    //If we have no experiences, add an empty one by default
    onMounted(() => {
        addEmptyExperience(true);
        addEmptyEducation(true);
    });
    watch(experiencesModel.value, (value) => {
        addEmptyExperience(true);
        addEmptyEducation(true);
    });
    function addEmptyExperience(isFirst) {
        const experienceModel = {
            title: '',
            type: '',
            company: '',
            city: '',
            startMonth: '',
            startYear: '',
            endMonth: '',
            endYear: '',
            currentlyWorking: false,
            description: '',
            editing: true,
        };
        if(experiencesModel.value.length < 1 && isFirst) {
            experienceModel.template = true;
            experiencesModel.value.push(experienceModel);
        }
        //If adding a new experience
        else if(!isFirst){
            experiencesModel.value.push(experienceModel);
        }
    }
    function addEmptyEducation(isFirst) {
        const educationTemplate = {
            title: '',
            institution: '',
            city: '',
            startMonth: '',
            startYear: '',
            endMonth: '',
            endYear: '',
            currentlyStudying: false,
            description: '',
            editing: true,
        };
        if(educationModel.value.length < 1 && isFirst) {
            educationTemplate.template = true;
            educationModel.value.push(educationTemplate);
        }
        //If adding a new experience
        else if(!isFirst){
            educationModel.value.push(educationTemplate);
        }
    }

    //Date validations
    const currentDate = new Date();
    function getSpecificMonths(isBefore, currentYear, otherMonth, otherYear, placeholder) {
        const returningMonths = [...months];
        const limitDate = new Date(parseInt(otherYear), parseInt(otherMonth - 1));
        
        //Make sure not in the future
        let formattedCurrentYear = parseInt(currentYear);
        if(formattedCurrentYear == currentDate.getFullYear()){
            returningMonths.length = currentDate.getMonth() + 1;
        }

        //Make sure we're before/after the other date
        if(otherMonth && otherYear) {
            if(isBefore) {
                if(currentYear && currentYear == otherYear) {
                    returningMonths.length = parseInt(otherMonth);
                }
            }
            else {
                if(currentYear && currentYear == otherYear) {
                    returningMonths.splice(0, parseInt(otherMonth - 1));
                }
                else if(currentDate.getFullYear() == otherYear) {
                    returningMonths.length = parseInt(currentDate.getMonth() + 1);
                    returningMonths.splice(0, parseInt(otherMonth - 1));
                }
            }
        }

        //Add placeholder value at beginning of array
        if(placeholder) {
            returningMonths.unshift({
                value: '',
                label: placeholder
            });
        }

        return returningMonths;
    }
    function getSpecificYears(isBefore, currentMonth, otherYear, placeholder) {
        const maxNbYears = 60;
        const currentYear = currentDate.getFullYear();
        const returningYears = new Array();
        for(let i = 0; i< maxNbYears; i++) {
            returningYears.push({
                value: currentYear - i,
                label: currentYear - i
            });
        }

        //Make sure we're before/after the other date
        if(otherYear && otherYear != currentYear) {
            if(isBefore) {
                returningYears.splice(0, currentYear - otherYear);
            }
            else{
                returningYears.length = currentYear - otherYear + 1;
            }
        }
        else if(otherYear && otherYear == currentYear) {
            if(!isBefore) {
                returningYears.length = 1;
            }
        }

        //Make sure we can't select the current year if selected month has not yet passed
        if(currentMonth && parseInt(currentMonth) > currentDate.getMonth() + 1){
            returningYears.splice(0, 1);
        }

        //Add placeholder value at beginning of array
        if(placeholder) {
            returningYears.unshift({
                value: '',
                label: placeholder
            });
        }

        return returningYears;
    }

    function editResume() {
        resumeEdited.value = true;
        document.querySelector('.step-1').scrollIntoView();
    }
</script>