<template>
    <div class="jobs__content">
        <div class="filters">
            <SelectDropdown
                name="categories" 
                v-model="categoriesModel"
                mode="multiple" 
                icon="/dist/static/images/svg/icon-category.svg" 
                :candeselect="true"
                :placeholder="labels['Job category']"
                :options="categories" />
            <SelectDropdown
                name="locations" 
                v-model="locationsModel"
                mode="multiple" 
                icon="/dist/static/images/svg/icon-location.svg" 
                :candeselect="true"
                :placeholder="labels['Job location']"
                :options="citiesTranslated" />
            <SelectDropdown
                name="categories" 
                v-model="typesModel"
                mode="multiple" 
                icon="/dist/static/images/svg/icon-job.svg" 
                :candeselect="true"
                :placeholder="labels['Job type']"
                :options="jobTypesTranslated" />
            <div class="filters__btn Button primary" @click="loadResults()">
                {{ labels['Apply filter'] }}
            </div>
        </div>
        <p class="jobsNb">
            {{ jobsCount }} {{ labels[jobsCount > 1 ? 'job openings' : 'job opening'] }}
        </p>
        <div class="list">
            <template v-for="(job, index) in jobs" :key="index">
                <template v-if="index == 3 || jobs.length < 3 && index == jobs.length - 1">
                    <JobInterstitial v-if="userId == 0"
                        :title="labels['Do you want to receive Job Alerts?']" 
                        :subtitle="labels['JobAlertTxt']">
                        <Button :text="labels['Log in/Sign up']" 
                            @click="openLogin(false)"
                            className="secondary" />
                    </JobInterstitial>
                    <JobInterstitial v-else
                        :title="labels['Set up Job Alerts']" 
                        :subtitle="labels['JobAlertTxt2']">
                        <select v-model="categoryAlert">
                            <option value="" 
                                disabled="disabled"
                                v-html="labels['Select a job category']" />
                            <option v-for="category in categoriesAlertFinal" 
                                :key="category.value" 
                                :value="category.value"
                                v-html="category.label" />
                        </select>
                        <Button :text="labels['Create an alert']" 
                            :className="`${categoryAlert ? '' : 'is-disabled'} secondary no-arrow`"
                            @click="createJobAlert" />
                    </JobInterstitial>
                </template>
                <JobCard :job="job" :labels="labels" />
                <template v-if="index == 12">
                    <JobInterstitial v-if="userId == 0"
                        :title="labels['Do you need help writing your CV?']" 
                        :subtitle="labels['BuildCVTxt']">
                        <Button :text="labels['Log in/Sign up']" 
                            @click="openLogin(false)"
                            className="secondary" />
                    </JobInterstitial>
                    <JobInterstitial v-else 
                        :title="labels['Set up Job Alerts']" 
                        :subtitle="labels['JobAlertTxt2']"
                        className="no-tablet">
                        <select v-model="categoryAlert">
                            <option value="" 
                                disabled="disabled"
                                v-html="labels['Select a job category']" />
                            <option v-for="category in categoriesAlertFinal" 
                                :key="category.value" 
                                :value="category.value"
                                v-html="category.label" />
                        </select>
                        <Button :text="labels['Set up Job Alerts']" 
                            :className="`${categoryAlert ? '' : 'is-disabled'} secondary no-arrow`"
                            @click="createJobAlert" />
                    </JobInterstitial>
                </template>
            </template>
            <div class="job-card empty" />
            <div class="job-card empty" />
            <div class="load-more__wrapper">
                <div class="load-more" @click="loadMore" v-if="!endOfResults">
                    {{ labels['Load more'] }}
                </div>
            </div>
        </div>
    </div>
    <Teleport to="body">
        <Transition>
            <Notification v-if="jobAlertSaved" 
                :title="labels['JobAlertSavecSucess']"
                @close="jobAlertSaved = false" />
        </Transition>
    </Teleport>
</template>

<script setup>
    import { computed, ref, onMounted } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import axios from 'axios';
    import qs from 'qs';
    import SelectDropdown from './SelectDropdown.vue';
    import JobCard from './JobCard.vue';
    import JobInterstitial from './JobInterstitial.vue';
    import Button from './Button.vue';
    import Notification from './Notification.vue';
    import { CitiesDropdown } from '../components/CitiesDropdown.js';
    import { JobTypesDropdown } from '../components/JobTypesDropdown.js';

    const props = defineProps([
        'labels',
        'categories',
        'categoriesAlert',
        'userId',
        'alertCategories',
        'siteHandle',
        'locations',
        'types',
    ]);

    const categoriesModel = ref([]);
    const locationsModel = ref([]);
    const typesModel = ref([]);
    let loadingMore = false;
    let page = 1;
    let pageOverridden = false;
    const limit = 13;
    const jobs = ref([]);
    const endOfResults = ref(false);
    const jobsCount = ref(0);

    const citiesTranslated = computed(() => {
        //If city is not empty, and is included in list of all used locations
        return CitiesDropdown.filter(city => {
            return props.locations.includes(city.value) && city.value !== ''
        }).map(function (city){
            city.label = props.labels[city.label];
            return city;
        });
    });
    const jobTypesTranslated = computed(() => {
        return JobTypesDropdown.filter(job => {
            return props.types.includes(job.value) && job.value !== ''
        }).map(function (job){
            job.label = props.labels[job.label];
            return job;
        });
    });
    const categoriesAlertFinal = computed(() => {
        return props.categoriesAlert.filter((category) => {
            return !props.alertCategories.some(e => e === category.value);
        });
    });

    onMounted(() => {
        //If page has query parameter, preselect location
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if(params.page) {
            pageOverridden = params.page;
        }
        if(params.location) {
            locationsModel.value = params.location.split('|');
        }
        if(params.category) {
            categoriesModel.value = params.category.split('|');
        }
        if(params.type) {
            typesModel.value = params.type.split('|');
        }
        
        loadResults();
    });

    function loadMore() {
        if(!loadingMore && !endOfResults.value) {
            loadingMore = true;
            page++;
            loadResults(true);
        }
    }

    function loadResults(more) {
        if(!more) {
            page = 1;
            endOfResults.value = false;
        }
        else {
            //Update query parameters
            const url = new URL(window.location);
            url.searchParams.set('page', page);
            url.search = decodeURIComponent(url.search);
            window.history.replaceState(null, '', url.toString());
        }

        const data = {
            page,
            limit: !pageOverridden ? limit : limit * pageOverridden,
            categories: categoriesModel.value,
            locations: locationsModel.value,
            types: typesModel.value,
            site: props.siteHandle,
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/get-jobs', data).then((response) => {
            pageOverridden = false;

            if(more) {
                jobs.value = [...jobs.value, ...response.data.jobs];

                setTimeout(() => {
                    loadingMore = false;
                }, 500);
            }
            else {
                jobs.value = response.data.jobs;
            }

            if(response.data.jobs.length < limit) {
                endOfResults.value = true;
            }

            jobsCount.value = response.data.count;

            //Update query parameters
            const url = new URL(window.location.origin + window.location.pathname);
            if(page != 1) {
                url.searchParams.set('page', page);
            }
            if(categoriesModel.value.length > 0) {
                url.searchParams.set('category', categoriesModel.value.join('|'));
            }
            if(locationsModel.value.length > 0) {
                url.searchParams.set('location', locationsModel.value.join('|'));
            }
            if(typesModel.value.length > 0) {
                url.searchParams.set('type', typesModel.value.join('|'));
            }
            url.search = decodeURIComponent(url.search);
            window.history.replaceState(null, '', url.toString());
        });
    }

    function openLogin(create) {
        document.querySelector('.menu-link.open-popup').click();

        if(create) {
            document.querySelector('.login-registration .switch .switch-create').click();
        }
    }

    const categoryAlert = ref('');
    const jobAlertSaved = ref(false);
    async function createJobAlert() {
        const data = {
                action: 'users/save-user',
                userId: props.userId,
                fields: {
                    wantsalert: 1,
                }
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            if(!props.alertCategories.includes(categoryAlert.value)) {
                data.fields.jobCategories = [...props.alertCategories, categoryAlert.value];
            }

            axios.post('/', qs.stringify(data)).then((response) => {
                if(!response.data.errors) {
                    jobAlertSaved.value = true;
                }
                else {
                    errorMessage.value = error;
                    notifErrorOpen.value = true;
                }
                
            }).catch((error) => {
                errorMessage.value = error;
                notifErrorOpen.value = true;
            });
    }
</script>