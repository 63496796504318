<template>
    <h1 class="title">
        {{ labels['Company profile'] }}
    </h1>
    <p class="subtitle">
        {{ labels['CompanyProfileTxt'] }}
    </p>
    <form class="main-content" @submit.prevent="submitForm">
        <div class="form-row is-double">
            <h2 class="form-row__title">
                {{ labels['Informations'] }}
            </h2>
            <CompanyInput id="businessName" 
                :labelText="labels['Company name*']"
                :placeholderText="labels['Name']"
                type="text"
                v-model="v$.businessName.$model"
                :error="v$.businessName.$errors.length" />
            <CompanyInput id="location" 
                :labelText="labels['Location*']"
                :placeholderText="labels['City']"
                type="select"
                v-model="v$.location.$model"
                :error="v$.location.$errors.length"
                :options="citiesTranslated" />
            <CompanyInput id="lang" 
                :labelText="labels['Company language*']"
                type="select"
                v-model="v$.langModel.$model"
                :error="v$.langModel.$errors.length"
                :options="LanguagesDropdown" />
            <FileUpload :text="labels['Drag and drop your logo or']"
                :cta="labels['Select file']"
                :note="labels['5mb max, image only']"
                :file="logo"
                id="logo"
                :key="fileUploadKey"
                :error="v$.logoModel.$errors.length"
                :errorMessage="labels['ImageUploaderror']"
                v-model="v$.logoModel.$model" />
        </div>
        <div class="form-row">
            <h2 class="form-row__title">
                {{ labels['Company description'] }}
            </h2>
            <CompanyInput id="description"
                :placeholderText="labels['Write here...']"
                type="textarea"
                v-model="v$.description.$model"
                :error="v$.description.$errors.length" />
        </div>
        <div class="form-row is-double">
            <h2 class="form-row__title">
                {{ labels['Field of activity'] }}
            </h2>
            <CompanyInput id="fieldOfActivity"
                type="select"
                v-model="v$.fieldOfActivityModel.$model"
                :error="v$.fieldOfActivityModel.$errors.length"
                :options="fieldsOfActivityTranslated" />
            <CompanyInput id="fieldOfActivityOther" 
                v-if="fieldOfActivityModel == 'other'"
                :labelText="labels['Specify*']"
                :placeholderText="labels['Specify*']"
                type="text"
                v-model="v$.fieldOfActivityOtherModel.$model"
                :error="v$.fieldOfActivityOtherModel.$errors.length" />
        </div>
        <div class="form-row is-double">
            <h2 class="form-row__title">
                {{ labels['Account private informations'] }}
            </h2>
            <CompanyInput id="email"
                type="email"
                :labelText="labels['Email address*']"
                :placeholderText="emailAddress"
                className="is-editable"
                :readonly="true"
                @click="isPopupEmailOpen = true" />
            <CompanyInput id="password" 
                type="password"
                :labelText="labels['Password*']"
                placeholderText="***********"
                className="is-editable"
                :readonly="true"
                @click="isPopupPasswordOpen = true" />
        </div>
        <div class="form-row is-error" v-if="errorMessage">
            {{ errorMessage }}
        </div>
        <div class="form-row is-double is-submit">
            <button>
                {{ isDirtied ? labels['Save modifications'] : labels['Save'] }}
            </button>
            <div class="button alt" @click="resetFields" v-if="isDirtied">
                {{ labels['Cancel'] }}
            </div>
        </div>
    </form>
    <Teleport to="body">
        <Transition>
            <Popup v-if="isPopupApprovalOpen"
                :title="labels['Congrats, your profile is completed']"
                @close="isPopupApprovalOpen = false">
                <p v-html="labels['CongratsPopupTxt']" />
                <button v-html="labels['OK, I understand']" @click="isPopupApprovalOpen = false" />
            </Popup>
        </Transition>
        <Transition>
            <Popup v-if="isPopupEmailOpen"
                :title="labels['Edit your Email']"
                @close="isPopupEmailOpen = false">
                <form v-if="!emailSubmitted" @submit.prevent="changeEmail">
                    <p v-html="labels['EditEmailTxt']" />
                    <div class="form-row">
                        <CompanyInput id="currentPassword" 
                            :labelText="labels['Current password*']"
                            type="password"
                            v-model="v$Email.currentPassword.$model"
                            :error="v$Email.currentPassword.$errors.length" />
                    </div>
                    <div class="form-row">
                        <CompanyInput id="newEmail1" 
                            :labelText="labels['New email address*']"
                            type="email"
                            v-model="v$Email.newEmail1.$model"
                            :error="v$Email.newEmail1.$errors.length" />    
                    </div>
                    <div class="form-row">
                        <CompanyInput id="newEmail2" 
                            :labelText="labels['Confirmation new email address*']"
                            type="email"
                            v-model="v$Email.newEmail2.$model"
                            :error="v$Email.newEmail2.$errors.length" />
                    </div>
                    <div class="form-row is-error" v-if="errorMessage">
                        {{ errorMessage }}
                    </div>
                    <button class="is-form" v-html="labels['Confirm this edit']" />
                </form>
                <p v-else v-html="labels['EditEmailTxtSubmit']" />
            </Popup>
        </Transition>
        <Transition>
            <Popup v-if="isPopupPasswordOpen"
                :title="labels['Change your Password']"
                @close="isPopupPasswordOpen = false">
                <form v-if="!passwordChanged" @submit.prevent="changePassword">
                    <div class="form-row">
                        <CompanyInput id="currentPassword" 
                            :labelText="labels['Current password*']"
                            type="password"
                            v-model="v$Password.currentPassword.$model"
                            :error="v$Password.currentPassword.$errors.length" />
                    </div>
                    <p class="note" v-html="labels['Portal:Password Check']" />
                    <div class="form-row">
                        <CompanyInput id="newPassword1" 
                            :labelText="labels['New password*']"
                            type="password"
                            v-model="v$Password.newPassword1.$model"
                            :error="v$Password.newPassword1.$errors.length" />
                    </div>
                    <div class="form-row">
                        <CompanyInput id="newPassword2" 
                            :labelText="labels['New password confirmation*']"
                            type="password"
                            v-model="v$Password.newPassword2.$model"
                            :error="v$Password.newPassword2.$errors.length" />
                    </div>
                    <div class="form-row is-error" v-if="errorMessage">
                        {{ errorMessage }}
                    </div>
                    <button class="is-form" v-html="labels['Confirm this edit']" />
                </form>
                <p v-else v-html="labels['ChangePwdTxtSubmit']" />
            </Popup>
        </Transition>
        <Transition>
            <Notification v-if="notifSuccessOpen" 
                :title="labels['ProfileSavecSucess']"
                @close="notifSuccessOpen = false" />
        </Transition>
        <Transition>
            <Notification v-if="notifErrorOpen" 
                :title="labels['SaveError']" 
                type="error"
                @close="notifErrorOpen = false" />
        </Transition>
    </Teleport>
</template>

<script setup>
    import { computed, reactive, ref, nextTick, watch } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import { required, requiredIf, helpers, email, sameAs } from '@vuelidate/validators';
    import axios from 'axios';
    import qs from 'qs';
    import { CitiesDropdown } from '../components/CitiesDropdown.js';
    import { FieldsOfActivityDropdown } from '../components/FieldsOfActivityDropdown.js';
    import { LanguagesDropdown } from '../components/LanguagesDropdown.js';
    import FileUpload from '../components/FileUpload.vue';
    import CompanyInput from './CompanyInput.vue';
    import Popup from './Popup.vue';
    import Notification from './Notification.vue';

    const props = defineProps([
        'labels',
        'logo',
        'businessName',
        'location',
        'description',
        'fieldOfActivity',
        'fieldOfActivityOther',
        'emailAddress',
        'userId',
        'approvedBusiness',
        'currentLang',
    ]);

    const errorMessage = ref('');
    const isPopupApprovalOpen = ref(false);
    const isPopupEmailOpen = ref(false);
    const isPopupPasswordOpen = ref(false);
    const notifSuccessOpen = ref(false);
    const notifErrorOpen = ref(false);
    const emailSubmitted = ref(false);
    const passwordChanged = ref(false);

    const citiesTranslated = computed(() => {
        return CitiesDropdown.map(function (city){
            city.label = props.labels[city.label];
            return city;
        });
    });
    const fieldsOfActivityTranslated = computed(() => {
        return FieldsOfActivityDropdown.map(function (field){
            field.label = props.labels[field.label];
            return field;
        });
    });

    //Form and validation stuff
    const businessName = ref(props.businessName);
    const location = ref(props.location);
    const logoModel = ref({});
    const langModel = ref(props.currentLang);
    const description = ref(props.description);
    const fieldOfActivityModel = ref(props.fieldOfActivity);
    const fieldOfActivityOtherModel = ref(props.fieldOfActivityOther);
    const currentPassword = ref('');
    const newEmail1 = ref('');
    const newEmail2 = ref('');
    const newPassword1 = ref('');
    const newPassword2 = ref('');
    const fileUploadKey = ref(0);
    const isDirtied = computed(() => {
        if(
            businessName.value != props.businessName ||
            location.value != props.location ||
            langModel.value != props.currentLang ||
            logoModel.value == null ||
            logoModel.value?.size || 
            description.value != props.description ||
            fieldOfActivityModel.value != props.fieldOfActivity ||
            fieldOfActivityOtherModel.value != props.fieldOfActivityOther
        ) {
            return true;
        }

        return false;
    });

    //Validations for main form
    const isImage = (value) => {
        if (value === null) {
            return false;
        }

        return (!helpers.req(value) && props.logo) || (value.type.indexOf('image') > -1 && value.size < 5000000);
    };
    const rules = computed(() => {
        return {
            businessName: {
                required,
            },
            location: {
                required,
            },
            logoModel: {
                isImage
            },
            description: {

            },
            langModel: {

            },
            fieldOfActivityModel: {
                required,
            },
            fieldOfActivityOtherModel: {
                required: requiredIf(fieldOfActivityModel.value == 'other')
            },
        }
    });
    const v$ = useVuelidate(rules, {businessName, location, logoModel, langModel, description, fieldOfActivityModel, fieldOfActivityOtherModel}, {
        $autoDirty: true,
        $lazy: true
    });

    //Validations for email change form
    const rulesEmail = computed(() => {
        return {
            currentPassword: {
                required
            },
            newEmail1: {
                required, email
            },
            newEmail2: {
                required, email, sameAs: sameAs(newEmail1)
            },
        }
    });
    const v$Email = useVuelidate(rulesEmail, {currentPassword, newEmail1, newEmail2}, {
        $autoDirty: true,
        $lazy: true
    });

    //Validations for password change form
    const passwordValidator = helpers.regex(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Z])(?=.*[!@?\/\\\.#$%\-_=+<>&*()|¯´~{\[\]\}]).{8,50}/);
    const rulesPassword = computed(() => {
        return {
            currentPassword: {
                required
            },
            newPassword1: {
                required, passwordValidator,
            },
            newPassword2: {
                required, sameAs: sameAs(newPassword1)
            },
        }
    });
    const v$Password = useVuelidate(rulesPassword, {currentPassword, newPassword1, newPassword2}, {
        $autoDirty: true,
        $lazy: true
    });

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    async function changeEmail() {
        v$Email.value.$validate();
        
        if (!v$Email.value.$error) {
            const data = {
                action: 'users/save-user',
                userId: props.userId,
                email: newEmail1.value,
                password: currentPassword.value,
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            axios.post('/', qs.stringify(data)).then((response) => {
                if(!response.data.errors) {
                    errorMessage.value = '';
                    notifSuccessOpen.value = true;
                    emailSubmitted.value = true;
                }
                else {
                    notifErrorOpen.value = true;

                    errorMessage.value = '';
                    for (const property in response.data.errors) {
                        errorMessage.value += response.data.errors[property] + ' ';
                    }
                }
                
            }).catch((error) => {
                errorMessage.value = error;
                notifErrorOpen.value = true;
            });
        }
        else {
            notifErrorOpen.value = true;
        }
    }

    function changePassword() {
        v$Password.value.$validate();
        
        if (!v$Password.value.$error) {
            const data = {
                action: 'users/save-user',
                userId: props.userId,
                password: currentPassword.value,
                newPassword: newPassword1.value,
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            axios.post('/', qs.stringify(data)).then((response) => {
                if(!response.data.errors) {
                    errorMessage.value = '';
                    notifSuccessOpen.value = true;
                    passwordChanged.value = true;
                }
                else {
                    notifErrorOpen.value = true;

                    errorMessage.value = '';
                    for (const property in response.data.errors) {
                        errorMessage.value += response.data.errors[property] + ' ';
                    }
                }
                
            }).catch((error) => {
                errorMessage.value = error;
                notifErrorOpen.value = true;
            });
        }
        else {
            notifErrorOpen.value = true;
        }
    }

    async function submitForm() {
        v$.value.$validate();

        if (!v$.value.$error) {
            const data = {
                action: 'users/save-user',
                userId: props.userId,
                fields: {
                    businessName: businessName.value,
                    location: location.value,
                    defaultLanguage: langModel.value,
                    description: description.value,
                    fieldOfActivity: fieldOfActivityModel.value,
                    fieldOfActivityOther: fieldOfActivityOtherModel.value,
                }
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            if(logoModel.value?.size) {
                data.photo = {
                    data: await getBase64(logoModel.value),
                    filename: logoModel.value.name,
                };
            }

            axios.post('/', qs.stringify(data)).then((response) => {
                errorMessage.value = '';
                notifSuccessOpen.value = true;

                if(!props.approvedBusiness) {
                    isPopupApprovalOpen.value = true;
                }
                else if(langModel.value != props.currentLang) {
                    //Si la langue a changée, on reload la page pour l'afficher dans la bonne langue
                    window.location.href = `${window.location.origin}/portal-redirect?page=profile`;
                }
            }).catch((error) => {
                errorMessage.value = error;
                notifErrorOpen.value = true;
            });
        }
        else {
            notifErrorOpen.value = true;

            nextTick(() => {	
                document.querySelector('.is-error').scrollIntoView();
            });
        }
    }

    function resetFields() {
        businessName.value = props.businessName;
        location.value = props.location;
        langModel.value = props.currentLang;
        logoModel.value = {};
        description.value = props.description;
        fieldOfActivityModel.value = props.fieldOfActivity;
        fieldOfActivityOtherModel.value = props.fieldOfActivityOther;

        //Besoin de la ligne suivante pour reset le file upload correctement
        fileUploadKey.value = fileUploadKey.value + 1;
    }

    //Si la langue a changée, on reload la page pour l'afficher dans la bonne langue
    watch(
        () => isPopupApprovalOpen.value,
        (val) => {
            if(!val && langModel.value != props.currentLang) {
                window.location.href = `${window.location.origin}/portal-redirect?page=profile`;
            }
        }
    );
</script>