<template>
    <h1 class="title">
        {{ labels['Search profiles'] }}
    </h1>
    <div class="filter-bar">
        <div class="filter-bar__inner">
            <SelectDropdown
                name="categories" 
                v-model="categoriesModel"
                mode="multiple" 
                icon="/dist/static/images/svg/profile.svg" 
                :candeselect="true"
                :placeholder="labels['Job key word']"
                :options="categories" />
            <SelectDropdown
                name="locations" 
                v-model="locationsModel"
                mode="multiple" 
                icon="/dist/static/images/svg/location.svg" 
                :candeselect="true"
                :placeholder="labels['Location']"
                :options="citiesTranslated" />
        </div>
        <button @click="() => {getCounts();loadResults();}">
            <img src="/dist/static/images/svg/search.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
            {{ labels['Search'] }}
        </button>
    </div>
    <table class="table profiles">
        <thead v-if="jobs.length > 0">
            <tr>
                <th :class="['selectable', sorterDirection, {'is-selected': selectedSorter == 'firstName'}]" @click="sort('firstName')">
                    {{ labels['Name'] }}
                </th>
                <th>
                    {{ labels['Job key word'] }}
                </th>
                <th :class="['selectable', sorterDirection, {'is-selected': selectedSorter == 'location'}]" @click="sort('location')">
                    {{ labels['Location'] }}
                </th>
                <th>
                    {{ labels['Spoken languages'] }}
                </th>
                <th>
                    {{ labels['Resume'] }}
                </th>
            </tr>
        </thead>
        <tbody :class="{'no-jobs': jobs.length < 1}">
            <tr v-for="user in jobs" :key="user.id">
                <td>
                    <a v-html="user.name" @click="viewUser(user.id)" />
                </td>
                <td>
                    <template v-for="(category, index) in getUserKeywordsByTag(user.categories)" :key="index">
                        <span class="keyword" v-if="index < 2" v-html="category.label" />
                    </template>
                    <span class="extra" v-if="user.categories.length > 2" 
                        v-html="'+' + (user.categories.length - 2)" />
                </td>
                <td v-html="labels[user.location]" />
                <td v-html="user.languages" />
                <td>
                    <a v-if="user.cv" :href="user.cv" target="_blank">
                        <img src="/dist/static/images/svg/download.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
                    </a>
                </td>
            </tr>
            <tr v-if="jobs.length < 1">
                <td colspan="5" v-html="labels['No users matching these criterias']" />
            </tr>
        </tbody>
    </table>
    <div class="pager" v-if="jobs.length > 0">
        <span class="prev" 
            v-html="labels['Prev.']"
            @click="page--"
            :class="{'is-disabled': page == 1}" />
        <span class="page" 
            v-for="pageElem in nbPages" 
            :key="pageElem" 
            :class="{'is-selected': pageElem == page}" 
            @click="page = pageElem"
            v-html="pageElem" />
        <span class="next" 
            v-html="labels['Next']"
            @click="page++"
            :class="{'is-disabled': page == nbPages || !nbPages}"/>
    </div>
    <Teleport to="body">
        <Transition>
            <Popup v-if="currentUser"
                :title="currentUser.name"
                className="user"
                @close="currentUser = false">
                <div class="infos">
                    <template v-if="currentUser.categories.length > 0">
                        <h2 v-html="labels['Jobs wanted']" />
                        <div>
                            <span class="keyword" 
                                v-for="(category, index) in currentUser.categories"
                                :key="index"
                                v-html="category" />
                        </div>
                    </template>
                    <h2 v-html="labels['Personal informations']" />
                    <CompanyInput id="languages"
                        type="text"
                        :labelText="labels['Spoken languages*']"
                        v-model="currentUserLanguages"
                        className="is-locked"
                        :readonly="true" />
                    <CompanyInput id="email"
                        type="text"
                        :labelText="labels['Email address*']"
                        v-model="currentUser.email"
                        className="is-locked"
                        :readonly="true" />
                    <CompanyInput id="phone"
                        type="text"
                        :labelText="labels['Phone number*']"
                        v-model="currentUser.phone"
                        className="is-locked"
                        :readonly="true" />
                    <CompanyInput id="location"
                        type="text"
                        :labelText="labels['Location*']"
                        v-model="labels[currentUser.location]"
                        className="is-locked"
                        :readonly="true" />
                    <a v-if="currentUser.cv" 
                        :href="currentUser.cv" 
                        class="button"
                        target="_blank">
                        {{ labels['Download resume'] }}
                        <img src="/dist/static/images/svg/download2.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
                    </a>
                </div>
                <div class="cv" v-if="currentUser.cvScreenshot">
                    <img :src="currentUser.cvScreenshot" alt="" fetchpriority="low" decoding="async" loading="lazy">
                </div>
            </Popup>
        </Transition>
    </Teleport>
</template>

<script setup>
    import { computed, ref, watch, onMounted } from 'vue';
    import axios from 'axios';
    import Popup from './Popup.vue';
    import CompanyInput from './CompanyInput.vue';
    import SelectDropdown from './SelectDropdown.vue';
    import { CitiesDropdown } from '../components/CitiesDropdown.js';

    const props = defineProps([
        'labels',
        'currentLang',
        'categories',
    ]);

    const citiesTranslated = computed(() => {
        //If city is not empty, and is included in list of all used locations
        return CitiesDropdown.filter(city => city.value !== '')
            .map(function (city){
            city.label = props.labels[city.label];
            return city;
        });
    });

    const jobs = ref([]);
    const categoriesModel = ref([]);
    const locationsModel = ref([]);

    //Used to sort/filter entries
    const selectedSorter = ref('firstName');
    const sorterDirection = ref('ASC');
    function sort(sorter) {
        if(selectedSorter.value == sorter) {
            sorterDirection.value = sorterDirection.value == 'ASC' ? 'DESC' : 'ASC';
        }
        else {
            selectedSorter.value = sorter;
        }
    }

    //Pager-related stuff
    const limit = ref(8);
    const page = ref(1);
    const nbPages = computed(() => {
        const nbPagesFinal = Math.ceil(jobsCounts.value / limit.value);

        return isNaN(nbPagesFinal) ? 1 : nbPagesFinal;
    });

    //Get numbers of jobs in each category
    const jobsCounts = ref();
    function getCounts() {
        const data = {
            categories: categoriesModel.value,
            locations: locationsModel.value,
        };
        data[window.csrfTokenName] = window.csrfTokenValue;
        
        axios.post('/get-user-count', data).then((response) => {
            jobsCounts.value = response.data;
        });
    }

    //Load results on mounted, or when any filter/selector/page changes
    onMounted(() => {
        loadResults();
        getCounts();
    });
    watch([selectedSorter, sorterDirection, page], () => {
        loadResults();
    });

    function loadResults(more) {
        const data = {
            page: page.value,
            limit: limit.value,
            categories: categoriesModel.value,
            locations: locationsModel.value,
            site: props.currentLang,
            sort: selectedSorter.value,
            sortDirection: sorterDirection.value,
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/get-users', data).then((response) => {
            jobs.value = response.data;
        });
    }

    const currentUser = ref(false);
    const currentUserLanguages = computed(() => {
        if(!currentUser.value) {
            return false;
        }

        let languagesString = "";
        currentUser.value.languages.forEach((language, index) => {
            languagesString += props.labels[language];
            if(index < currentUser.value.languages.length - 1) {
                languagesString += ", ";
            }
        });
        return languagesString;
    });
    function viewUser(id) {
        const data = {
            id,
            site: props.currentLang,
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/get-user', data).then((response) => {
            currentUser.value = response.data;
        });
    }
    //If filtering by keyword, we sort those keywords first
    function getUserKeywordsByTag(keywords) {
        if(keywords.length) {
            const keywordsCopy = [...keywords];
            if(categoriesModel.value) {
                categoriesModel.value.forEach((selectedCat) => {
                    keywordsCopy.sort((x,y) => {
                        return x.value == selectedCat ? -1 : y.value == selectedCat ? 1 : 0;
                    });
                });
            }

            return keywordsCopy;
        }

        return null;
    }
</script>