export default class Header {
    static init() {
        //Dropdown/Click interactions
        document.querySelectorAll('.menu-links .dropdown, .actions-links .dropdown').forEach((elem) => {
            elem.addEventListener('click', () => {
                elem.classList.toggle('open');
            });
        });
        const burgerMenu = document.querySelector('#burger-menu');
        if(burgerMenu) {
            document.querySelector('#burger-menu').addEventListener('click', (burger) => {
                burger.currentTarget.classList.toggle('open');
                document.querySelector('#mobile-menu').classList.toggle('is-opened');
            });
        }
        const profileLink = document.querySelector('.menu-link__registration:not(.open-popup)');
        if(profileLink) {
            profileLink.addEventListener('click', (event) => {
                profileLink.classList.toggle('open');
            });
        }

        //Disclaimer
        const disclaimer = document.querySelector('.disclaimer');
        if(disclaimer) {
            document.querySelector('.disclaimer__close').addEventListener('click', function() {
                disclaimer.remove();
                setHeaderHeight();
            });
        }

        //Calculate header height for sticky
        setHeaderHeight();
        let resizeTimeout;
        window.onresize = function(){
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(setHeaderHeight, 100);
        };
        function setHeaderHeight() {
            const header = document.querySelector('#main-header');
            if(header) {
                let totalHeight = header.offsetHeight;

                if(disclaimer) {
                    header.style.top = `${disclaimer.offsetHeight}px`;
                    totalHeight += disclaimer.offsetHeight;
                }

                document.querySelector('.page__wrapper').style.paddingTop = `${totalHeight}px`;
                const portal = document.querySelector('.portal');
                if(portal) {
                    portal.style.height = `calc(100vh - ${totalHeight}px)`;
                }
            }
        }

        // Copy mobile langs to footer (Save a query!)
        const mobileLangs = document.querySelector('#mobile-menu .languages');
        const footerLangs = document.querySelector('.Footer .languages');
        if(footerLangs) {
            footerLangs.innerHTML = footerLangs.innerHTML + mobileLangs.innerHTML.replaceAll('menu-link', 'footer-link language');
        }

        // Language switcher footer mobile
        const selectedLang = document.querySelector('.Footer .columns .language.router-link-active');
        if(selectedLang) {
            selectedLang.addEventListener('click', function(e) {
                if(document.querySelector('#main-header .menu-wrapper #burger-menu').offsetParent !== null) {
                    e.preventDefault();
                    this.parentElement.classList.toggle('is-open');
                }
            });
        }

        document.body.classList.remove("preload");

        //Notifications
        document.querySelectorAll('.notification').forEach((elem) => {
            elem.addEventListener('click', () => {
                elem.remove();
            });

            setTimeout(() => {
                elem.remove();
            }, 5000);
        });

        //Footer
        const prefooter = document.querySelector('.Prefooter');
        const footer = document.querySelector('.Footer');
        if(!prefooter && footer) {
            footer.classList.add('no-prefooter');
        }
    }
}

if (process.env.NODE_ENV == 'development') {
    window.Header = Header;
}
