<template>
    <div class="form-row">
        <h2 class="form-row__title">
            {{ labelText }}
            <div v-if="showPublish" class="publish">
                {{ labels[mq.mobile ? 'Publish' : 'Publish these informations'] }}
                <label class="lightswitch">
                    <input type="checkbox"
                        :checked="publish"
                        @change="$emit('update:publish', $event.target.checked)" />
                    <span />
                </label>
            </div>
        </h2>
        <label class="form-input" 
            :class="[className, {'is-error': error}]" 
            v-for="language in allLanguagesArray"
            :key="language">
            <template v-if="activeLanguages.includes(language)">
                <div class="form-languages">
                    <span v-html="labels['English']" :class="{'is-selected': language == 'en'}" />
                    <span v-html="labels['French']" :class="{'is-selected': language == 'fr'}" />
                    <span v-html="labels['Inuktitut']" :class="{'is-selected': language == 'iu'}" />
                </div>
                <QuillEditor theme="snow" 
                    :id="id + language"
                    v-if="type == 'rich'"
                    :ref="skipUnwrap.el"
                    contentType="html"
                    :toolbar="[{size:['small',false,'large']}, 'bold','italic',{list: 'bullet'},{list: 'ordered'}]"
                    :placeholder="placeholderText"
                    :content="input[language]"
                    @ready="clearContent"
                    @blur="manageChange" />
                <input v-else
                    :ref="skipUnwrap.el"
                    :value="input[language]"
                    @change="manageChange"
                    :id="id + language"
                    :type="type"
                    :placeholder="placeholderText"
                />
            </template>
        </label>
        <div class="form-row__add-language" 
            v-if="activeLanguages.length < 3"
            @click="languagesDropdownOpen = !languagesDropdownOpen">
            <span />
            {{ labels['Add a description in another language'] }}
            <div class="dropdown" v-if="languagesDropdownOpen">
                <div v-html="labels['English']" 
                    v-if="!activeLanguages.includes('en')"
                    @click="activeLanguages.push('en')" />
                <div v-html="labels['French']" 
                    v-if="!activeLanguages.includes('fr')"
                    @click="activeLanguages.push('fr')" />
                <div v-html="labels['Inuktitut']" 
                    v-if="!activeLanguages.includes('iu')"
                    @click="activeLanguages.push('iu')" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref, watch, nextTick } from 'vue'
    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';
    import { useMq } from "vue3-mq";

    const props = defineProps({
        id: String,
        labelText: String,
        placeholderText: String,
        input: Object,
        publish: Boolean,
        showPublish: Boolean,
        type: {
            type: String,
            default: 'text'
        },
        className: String,
        error: Number,
        labels: Object,
        defaultLang: {
            type: String,
            default: 'en',
        },
    });

    const mq = useMq();

    const allLanguagesArray = ['en','fr','iu'];
    const activeLanguages = ref([props.defaultLang]);
    watch(props, () => {
        //By default, only the main language is active. If we receive prop content in a different language, add it to the activated languages list
        activeLanguages.value = [props.defaultLang];

        allLanguagesArray.forEach((language) => {
            if(props.input[language] && props.defaultLang != language) {
                activeLanguages.value.push(language);
            }
        });
    });


    const languagesDropdownOpen = ref(false);

    //I know the following code is a mess, but otherwise, the rich-text editor wouldn't work correctly
    const skipUnwrap = {el: ref([])};
    let el = computed(() => skipUnwrap.el.value);
    function clearContent(prop) {
        //If any ref of rich text editor exists (so it's current part of the active languages), get it, reset the html (otherwise it would be undefined), and paste the html of the prop
        if(props.type == 'rich') {
            el.value.forEach((aRef) => {
                if(aRef) {
                    let currentId = {...aRef}.editor?.id;
                    if(!currentId) {
                        currentId = aRef.getQuill().container.id
                    }
                    aRef.setHTML('');
                    setTimeout(() => {
                        nextTick(() => {
                            aRef.pasteHTML(props.input[currentId.slice(-2)]);
                        });
                    }, 750);
                }
            });
        }
    }

    //On input change, we emit an event containing an object with all current languages and their values
    const emit = defineEmits(['update:input']);
    function manageChange(event) {
        let newValue = {};

        el.value.forEach((aRef) => {
            let currentId = {...aRef}.editor?.id;
            if(!currentId && props.type == 'rich') {
                currentId = aRef.getQuill().container.id;
            }
            newValue[props.type == 'rich' ? currentId.slice(-2) : aRef.id.slice(-2)] = props.type == 'rich' ? aRef.getHTML() : aRef.value;
        });

        emit('update:input', newValue);
    }
</script>