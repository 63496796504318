export default class Toggle {
    static init() {
        document.querySelectorAll('.toggle').forEach((elem) => {
            elem.querySelector('.toggle-click').addEventListener('click', () => {
                elem.classList.toggle('is-open');
            });
        });
    }
}

if (process.env.NODE_ENV == 'development') {
    window.Toggle = Toggle;
}
