export default class Popup {
    static init() {
        const openClass = 'is-open';

        document.querySelectorAll('.popup__wrapper').forEach((elem) => {
            const name = elem.dataset.name;
            
            document.querySelectorAll(`.open-popup[data-name="${name}"]`).forEach((elemToggle) => {
                elemToggle.addEventListener('click', (e) => {
                    e.preventDefault();
                    elem.classList.toggle(openClass);

                    if(name == 'login-registration' && elem.classList.contains(openClass)) {
                        elem.classList.remove('signup', 'resetpassword');
                        elem.classList.add('login');
                        window.location.hash = '#login';
                    }
                });
            });

            elem.querySelectorAll('.popup__bg, .popup__close').forEach((elemClose) => {
                elemClose.addEventListener('click', () => {
                    elem.classList.remove(openClass);
                    removeHash();
                });
            });
        });

        document.body.addEventListener('keydown', function(e) {
            if (e.key == "Escape") {
                document.querySelectorAll('.popup__wrapper.is-open').forEach((popup) => {
                    popup.classList.remove(openClass);
                    removeHash();
                });
            }
        });

        function removeHash () { 
            history.pushState("", document.title, window.location.pathname + window.location.search);
        }
    }
}

if (process.env.NODE_ENV == 'development') {
    window.Toggle = Popup;
}
