<template>
    <div class="notification" 
        :class="{'is-error': type == 'error'}"
        @click="closeNotif" 
        v-html="title" />
</template>

<script setup>
    import { onMounted } from 'vue';

    const props = defineProps({
        title: String,
        type: String
    });
    const emit = defineEmits(['close']);

    onMounted(() => {
        setTimeout(() => {
            closeNotif();
        }, 5000);
    })


    function closeNotif() {
        emit('close');
    }
</script>
