<template>
    <a :href="labels['/portal/employer/trainings']" 
        class="back"
        v-html="labels['Back']" />
    <h1 class="title with-actions">
        {{ jobId ? labels['Your training'] : labels['Create a training offer'] }}
        <div class="actions"
            v-if="jobId"
            :class="{'is-opened': openedAction}"
            @click="openedAction = !openedAction">
            <img src="/dist/static/images/svg/settings.svg" alt="" />
            <ul v-if="openedAction">
                <li @click="deletingJob = true">
                    {{ labels['Delete'] }}
                </li>
                <li v-if="!isArchived" @click="archiveJob">
                    {{ labels['Archive'] }}
                </li>
                <li v-else @click="activateJob">
                    {{ labels['Activate'] }}
                </li>
            </ul>
        </div>
    </h1>
    <form class="main-content" @submit.prevent="checkFormSubmit">
        <CompanyLanguageInput id="title" 
            :labelText="labels['Training title*']"
            v-model:input="v$.title.$model"
            :error="v$.title.$errors.length"
            :defaultLang="currentLang"
            :labels="labels" />
        <div class="form-row is-double">
            <h2 class="form-row__title">
                {{ labels['General informations'] }}
            </h2>
            <CompanyInput id="location" 
                :labelText="labels['Location*']"
                :placeholderText="labels['City']"
                type="select"
                v-model="v$.location.$model"
                :error="v$.location.$errors.length"
                :options="citiesTranslated" />
            <CompanyInput id="sector" 
                :labelText="labels['Training sector*']"
                :placeholderText="labels['Training sector*']"
                type="select"
                v-model="v$.sector.$model"
                :error="v$.sector.$errors.length"
                :options="sectorsList" />
            <SelectDropdown
                name="categories" 
                v-model="v$.categories.$model"
                mode="multiple" 
                :error="v$.categories.$errors.length"
                :alternatecaret="true"
                :candeselect="true"
                :placeholder="labels['Training categories*']"
                :options="categoriesList" />
        </div>
        <CompanyLanguageInput id="benefits" 
            :labelText="labels['Benefits']"
            :placeholderText="labels['Write here...']"
            v-model:input="v$.benefices.$model"
            v-model:publish="beneficesPublish"
            :showPublish="true"
            :defaultLang="currentLang"
            type="rich"
            :labels="labels" />
        <CompanyLanguageInput id="courses" 
            :labelText="labels['Courses offered']"
            :placeholderText="labels['Write here...']"
            v-model:input="v$.courses.$model"
            v-model:publish="coursesPublish"
            :showPublish="true"
            :defaultLang="currentLang"
            type="rich"
            :labels="labels" />
        <CompanyLanguageInput id="conditions" 
            :labelText="labels['Conditions']"
            :placeholderText="labels['Write here...']"
            v-model:input="v$.conditions.$model"
            v-model:publish="conditionsPublish"
            :showPublish="true"
            :defaultLang="currentLang"
            type="rich"
            :labels="labels" />
        <CompanyLanguageInput id="prerequisites" 
            :labelText="labels['Prerequisites']"
            :placeholderText="labels['Write here...']"
            v-model:input="v$.prerequisites.$model"
            v-model:publish="prerequisitesPublish"
            :showPublish="true"
            :defaultLang="currentLang"
            type="rich"
            :labels="labels" />
        <CompanyLanguageInput id="locationDescription" 
            :labelText="labels['Location description']"
            :placeholderText="labels['Write here...']"
            v-model:input="v$.locationDescription.$model"
            v-model:publish="locationDescriptionPublish"
            :showPublish="true"
            :defaultLang="currentLang"
            type="rich"
            :labels="labels" />
        <CompanyLanguageInput id="applicationDeadline" 
            :labelText="labels['Application deadline']"
            :placeholderText="labels['Write here...']"
            v-model:input="v$.applicationDeadline.$model"
            v-model:publish="applicationDeadlinePublish"
            :showPublish="true"
            :defaultLang="currentLang"
            type="rich"
            :labels="labels" />
        <div class="form-row is-double is-submit">
            <template v-if="jobId">
                <div class="button" @click="checkFormSubmit">
                    {{ labels['Save modifications'] }}
                </div>
                <div class="button alt" @click="reloadPage">
                    {{ labels['Cancel'] }}
                </div>
            </template>
            <template v-else>
                <div class="button" @click="checkFormSubmit">
                    {{ labels['Publish training'] }}
                </div>
                <a :href="labels['/portal/employer/trainings']" class="button alt">
                    {{ labels['Cancel'] }}
                </a>
            </template>
        </div>
    </form>
    <Teleport to="body">
        <Transition>
            <Popup v-if="clickedPublish && !fullyTranslated"
                :title="labels['Warning']"
                @close="clickedPublish = false">
                <p v-html="labels['UnpubJobWarningMsg']" />
                <div class="buttons">
                    <button v-html="labels['Edit my training']" 
                        @click="clickedPublish = false"
                        className="secondary" />
                    <button v-html="labels['Publish my training']" @click="() => {byPassFullyTranslated = true; checkFormSubmit();}" />
                </div>
            </Popup>
        </Transition>
        <Transition>
            <Popup v-if="deletingJob"
                :title="labels['Are you sure you want to delete this training?']"
                @close="deletingJob = false">
                <div class="buttons">
                    <button v-html="labels['No']" 
                        @click="deletingJob = false"
                        className="secondary" />
                    <button v-html="labels['Yes']" @click="deleteJob" />
                </div>
            </Popup>
        </Transition>
        <Transition>
            <Notification v-if="notifSuccess != ''" 
                :title="notifSuccess"
                @close="notifSuccess = ''" />
        </Transition>
        <Transition>
            <Notification v-if="notifError != ''" 
                :title="notifError" 
                type="error"
                @close="notifError = ''" />
        </Transition>
    </Teleport>
</template>

<script setup>
    import { computed, ref, onMounted, inject, nextTick, watch } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import { required, minLength } from '@vuelidate/validators';
    import axios from 'axios';
    import qs from 'qs';
    import { CitiesDropdown } from '../components/CitiesDropdown.js';
    import SelectDropdown from './SelectDropdown.vue';
    import CompanyInput from './CompanyInput.vue';
    import CompanyLanguageInput from './CompanyLanguageInput.vue';
    import Popup from './Popup.vue';
    import Notification from './Notification.vue';

    const props = defineProps([
        'labels',
        'currentLang',
        'categoriesList',
        'sectorsList',
        'userApproved',
        'userId',
        'sectionId',
    ]);

    const getSiteId = inject('getSiteId');

    const citiesTranslated = computed(() => {
        return CitiesDropdown.map(function (city){
            city.label = props.labels[city.label];
            return city;
        });
    });

    //Form and validation stuff
    const isArchived = ref(false);
    const location = ref('');
    const sector = ref('');
    const categories = ref([]);
    const title = ref({});
    const benefices = ref({});
    const beneficesPublish = ref(true);
    const courses = ref({});
    const coursesPublish = ref(true);
    const conditions = ref({});
    const conditionsPublish = ref(true);
    const prerequisites = ref({});
    const prerequisitesPublish = ref(true);
    const locationDescription = ref({});
    const locationDescriptionPublish = ref(true);
    const applicationDeadline = ref({});
    const applicationDeadlinePublish = ref(true);

    //Custom required validation for fields with multiple languages
    const multiLangRequired = (value) => {
        if(value.hasOwnProperty(props.currentLang) && value[props.currentLang]) {
            return true;
        }
        return false;
    };
    const rules = computed(() => {
        return {
            location: {
                required,
            },
            sector: {
                required,
            },
            categories: {
                required, 
                minLength: minLength(1),
            },
            title: {
                multiLangRequired,
            },
            benefices: {
                
            },
            courses: {
                
            },
            conditions: {
                
            },
            prerequisites: {
                
            },
            locationDescription: {
                
            },
            applicationDeadline: {
                
            },
        }
    });
    const v$ = useVuelidate(rules, {location, sector, categories, title, benefices, courses, conditions, prerequisites, locationDescription, applicationDeadline }, {
        $autoDirty: true,
        $lazy: true
    });

    const notifSuccess = ref('');
    const notifError = ref('');
    const openedAction = ref(false);
    const jobId = ref(false); //id of current job (if editing an existing one)
    const newJobId = ref(false); //if of newly created job, briefly used to save the new job in other languages
    let savedLangs = ref(0);

    async function submitForm() {
        //First we save the entry in the current language
        const data = {
            action: 'entries/save-entry',
            siteId: getSiteId(props.currentLang),
            sourceId: jobId.value ? jobId.value : "",
            title: title.value[props.currentLang],
            fields: {
                location: location.value,
                trainingCategories: categories.value,
                trainingSectors: [sector.value],
                benefices: benefices.value[props.currentLang],
                //+[VALUE] converts true/false boolean to a number
                publishBenefices: +beneficesPublish.value,
                courses: courses.value[props.currentLang],
                publishCourses: +coursesPublish.value,
                conditions: conditions.value[props.currentLang],
                publishConditions: +conditionsPublish.value,
                prerequisites: prerequisites.value[props.currentLang],
                publishPrerequisites: +prerequisitesPublish.value,
                locationDescription: locationDescription.value[props.currentLang],
                publishLocationDescription: +locationDescriptionPublish.value,
                applicationDeadline: applicationDeadline.value[props.currentLang],
                publishApplicationDeadline: +applicationDeadlinePublish.value,
            }
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        //If creating a new entry. Need both typeId and sectionId, otherwise wouldn't work 🤷‍♂️
        if(!jobId.value) {
            data['fields']['entryEnable'] = props.userApproved;
            data['sectionId'] = props.sectionId;
            data['authorId'] = props.userId;
        }

        axios.post('/', qs.stringify(data)).then((response) => {
            //Every successfull save in a language, increment this so we watch when we're done
            savedLangs.value++;
            //Get remaining languages to be saved
            let secondaryLanguages = ['en','fr','iu'];
            secondaryLanguages = secondaryLanguages.filter(function(language) {
                return language !== props.currentLang;
            });
            //If creating a new job, set the id of the new job
            if(!jobId.value) {
                newJobId.value = response.data?.id;
            }

            //Loop through remaining languages and only save data that can be translated
            secondaryLanguages.forEach((language) => {
                const data2 = {
                    action: 'entries/save-entry',
                    siteId: getSiteId(language),
                    sourceId: jobId.value ? jobId.value : newJobId.value,
                    title: title.value[language] == '' ? title.value[props.currentLang] : title.value[language],
                    fields: {
                        benefices: benefices.value[language],
                        courses: courses.value[language],
                        conditions: conditions.value[language],
                        prerequisites: prerequisites.value[language],
                        locationDescription: locationDescription.value[language],
                        applicationDeadline: applicationDeadline.value[language],
                    }
                };
                data2[window.csrfTokenName] = window.csrfTokenValue;

                axios.post('/', qs.stringify(data2)).then((response) => {
                    savedLangs.value++;
                }).catch((error) => {
                    notifError.value = props.labels['SaveError'];
                });
            });
        }).catch((error) => {
            notifError.value = props.labels['SaveError'];
        });
    }
    watch(savedLangs, (value) => {
        //If we successfully saved in all 3 languages
        if(value >= 3) {
            if(jobId.value) {
                notifSuccess.value = props.labels['Training edited'];
            }
            else {
                notifSuccess.value = props.labels['Training created'];
                window.location.href = props.labels['/portal/employer/training'] + '?id=' + newJobId.value;
            }
        }
    });

    onMounted(() => {
        loadJobData();
    });
    function loadJobData() {
        //If page has query parameter, we're editing an existing job
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if(params.id) {
            const data = {
                id: params.id,
                mainLang: props.currentLang,
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            axios.post('/get-business-training', data).then((response) => {
                if(response.data) {
                    //set jobId only if we know the job really exists
                    jobId.value = params.id;
                    isArchived.value = response.data.archived;
                    location.value = response.data.location;
                    categories.value = response.data.categories;
                    title.value = response.data.title;
                    sector.value = String(response.data.sector);
                    benefices.value = response.data.benefices;
                    beneficesPublish.value = +response.data.publishBenefices;
                    courses.value = response.data.courses;
                    coursesPublish.value = +response.data.publishCourses;
                    conditions.value = response.data.conditions;
                    conditionsPublish.value = +response.data.publishConditions;
                    prerequisites.value = response.data.prerequisites;
                    prerequisitesPublish.value = +response.data.publishPrerequisites;
                    locationDescription.value = response.data.locationDescription;
                    locationDescriptionPublish.value = +response.data.publishLocationDescription;
                    applicationDeadline.value = response.data.applicationDeadline;
                    applicationDeadlinePublish.value = +response.data.publishApplicationDeadline;
                }
            });
        }
    }

    //Deleting, archiving & editing jobs is pretty the much the same as in the portal listing
    const deletingJob = ref(false);
    function deleteJob() {
        const data = {
            action: 'entries/delete-entry',
            siteId: getSiteId(props.currentLang),
            sourceId: jobId.value
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/', qs.stringify(data)).then((response) => {
            if(!response.data.errors) {
                window.location.href = props.labels['/portal/employer/trainings'];
            }
            else {
                notifError.value = props.labels['DeleteError'];
            }

            deletingJob.value = false;
            
        }).catch((error) => {
            notifError.value = props.labels['DeleteError'];
            deletingJob.value = false;
        });
    }
    function archiveJob() {
        const data = {
            action: 'entries/save-entry',
            siteId: getSiteId(props.currentLang),
            sourceId: jobId.value,
            fields: {
                entryArchive: 1
            }
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/', qs.stringify(data)).then((response) => {
            if(!response.data.errors) {
                notifSuccess.value = props.labels['Training archived'];
                isArchived.value = true;
            }
            else {
                notifError.value = props.labels['SaveError'];
            }
            
        }).catch((error) => {
            notifError.value = props.labels['SaveError'];
        });
    }
    function activateJob() {
        const data = {
            action: 'entries/save-entry',
            siteId: getSiteId(props.currentLang),
            sourceId: jobId.value,
            fields: {
                entryArchive: 0
            }
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/', qs.stringify(data)).then((response) => {
            if(!response.data.errors) {
                notifSuccess.value = props.labels['Training activated'];
                isArchived.value = false;
            }
            else {
                notifError.value = props.labels['SaveError'];
            }
            
        }).catch((error) => {
            notifError.value = props.labels['SaveError'];
        });
    }

    const clickedPublish = ref(false); //If the user clicked on save
    const byPassFullyTranslated = ref(false); //If the "unfinished translations" popin appeared, and the user clicked "save"
    //Checks if the current job is "fully translated"
    const fullyTranslated = computed(() => {
        let returningValue = true;
        //If the user chose to bypass the warning popup, return directly
        if(byPassFullyTranslated.value) {
            return returningValue;
        }

        //If the title itself is not translated, return false
        if(!Object.keys(title.value).length) {
            returningValue = false;
        }

        //Our baseline are the languages used in the title field
        const allLanguagesArray = [];
        for (const property in title.value) {
            if(title.value[property]) {
                allLanguagesArray.push(property);
            }
        }

        //Loop through the other translatable fields. 
        const allTranslatableFields = [benefices,courses,conditions,prerequisites,locationDescription,applicationDeadline];
        allTranslatableFields.every((field) => {
            let nbSetValues = 0;

            //If current translatable fields doesn't have content in the same languages as the title fields, we must return false
            allLanguagesArray.forEach((language) => {
                if(field.value[language] && field.value[language].replace(/(<([^>]+)>)/gi, "")) {
                    nbSetValues++;
                }
            });

            if(nbSetValues != allLanguagesArray.length && nbSetValues != 0) {
                returningValue = false;
                return false;
            }

            return true;
        });

        return returningValue;
    });

    function checkFormSubmit() {
        v$.value.$validate();

        //First check if fields are valid
        if (!v$.value.$error) {
            //The check if the job is fully translated
            if(fullyTranslated.value || byPassFullyTranslated.value) {
                submitForm();
            }
            else {
                clickedPublish.value = true;
            }
        }
        else {
            notifError.value = props.labels['RequiredFieldsError'];
            byPassFullyTranslated.value = false;
            clickedPublish.value = false;

            //Scroll to first error field
            nextTick(() => {	
                document.querySelector('.is-error').scrollIntoView();
            });
        }
    }

    function reloadPage() {
        window.location.reload();
    }
</script>