export const LanguagesDropdown = [
    {
        label: 'English',
        value: 'en',
    },
    {
        label: 'Français',
        value: 'fr',
    },
    {
        label: 'ᐃᓄᒃᑎᑐᑦ',
        value: 'iu',
    },
];