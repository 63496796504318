export default class LoginRegistration {
    static init() {
        const popup = document.querySelector('.login-registration');
            
        if(popup) { 
            //Submit de forms
            popup.querySelectorAll('input').forEach((input) => {
                input.addEventListener('keyup', (event) => {
                    if (event.key === "Enter") {
                        input.closest('form').submit();
                    }
                });
            });

            //Switch de sections
            const errorMsg = popup.querySelector('.popup__content > .error');
            popup.querySelectorAll('.switch-create').forEach((switchCreate) => {
                switchCreate.addEventListener('click', (e) => {
                    e.preventDefault();
                    popup.classList.remove('login', 'resetpassword');
                    popup.classList.add('signup');
                    window.location.hash = '#register';
                    if(errorMsg) {
                        errorMsg.remove();
                    };
                });
            });
            popup.querySelector('.switch-login').addEventListener('click', (e) => {
                e.preventDefault();
                popup.classList.remove('signup', 'resetpassword');
                popup.classList.add('login');
                window.location.hash = '#login';
                if(errorMsg) {
                    errorMsg.remove();
                };
            });
            popup.querySelector('.forgot-password').addEventListener('click', (e) => {
                e.preventDefault();
                popup.classList.remove('login', 'signup');
                popup.classList.add('resetpassword');
                window.location.hash = '#forgot-password';
                if(errorMsg) {
                    errorMsg.remove();
                };
            });

            //Validations de form
            const form = popup.querySelector(".signup form");
            form.addEventListener('submit', (event) => {
                if(form.querySelector('#password2').value != form.querySelector('#password-confirmation').value) {
                    event.preventDefault();
                    form.querySelector('.password-match').style.display = 'block';
                }
            });

            const registerFb = popup.querySelector('form[name="register"] .facebook');
            document.register.accountType.forEach((radio) => {
                radio.addEventListener('change', function() {
                    if(this.value == 'business') {
                        registerFb.style.display = 'none';
                    }
                    else {
                        registerFb.style.display = 'block';
                    }
                });
            })

            //Ouvrir le popup/section si on a un hash dans l'url
            const hash = window.location.hash;
            const notification = document.querySelector('.notification:not(.is-error)');
            const portal = document.querySelector('.portal');
            if(hash) {
                if(['#login', '#login-1', '#register', '#forgot-password'].indexOf(hash) > -1 && !portal) {
                    popup.classList.add('is-open');

                    if(hash == '#register') {
                        if(!notification) {
                            popup.classList.remove('login');
                            popup.classList.add('signup');
                        }
                        else { //Successfully registered
                            popup.querySelector('.is-registered').style.display = 'block';
                        }
                    }
                    else if(hash == '#forgot-password' && !notification) {
                        popup.classList.remove('login');
                        popup.classList.add('resetpassword');
                    }
                    else if(hash == '#login-1') {
                        popup.querySelector('.email-validated').style.display = 'block';
                    }
                }
            }
        }
    }
}

if (process.env.NODE_ENV == 'development') {
    window.Toggle = LoginRegistration;
}
