<template>
    <div class="trainings__content">
        <div class="filters">
            <h4 class="title">
                {{ labels['Filter by Location'] }}
            </h4>
            <SelectDropdown
                name="locations" 
                v-model="locationsModel"
                mode="multiple" 
                icon="/dist/static/images/svg/icon-location.svg" 
                :candeselect="true"
                :placeholder="labels['Select a location']"
                :options="citiesTranslated" />
            <div class="Button primary" @click="loadResults()">
                {{ labels['Filter'] }}
            </div>
        </div>
        <template v-for="(training, index) in trainings" :key="index">
            <img v-if="index == 5" src="/dist/static/images/img-trainings-1@2x.webp" alt="" fetchpriority="low" decoding="async" loading="lazy" class="training-interstitial">
            <TrainingCard :training="training" :labels="labels" />
            <img v-if="index == 11" src="/dist/static/images/img-trainings-2@2x.webp" alt="" fetchpriority="low" decoding="async" loading="lazy" class="training-interstitial alt no-mobile">
        </template>
        <div class="training-card empty" />
        <div class="training-card empty" />
        <div class="load-more__wrapper">
            <div class="load-more" @click="loadMore" v-if="!endOfResults">
                {{ labels['Load more'] }}
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref, onMounted } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import axios from 'axios';
    import SelectDropdown from './SelectDropdown.vue';
    import TrainingCard from './TrainingCard.vue';
    import Button from './Button.vue';
    import { CitiesDropdown } from '../components/CitiesDropdown.js';

    const props = defineProps([
        'labels',
        'siteHandle',
        'locations',
    ]);

    const locationsModel = ref([]);
    let loadingMore = false;
    let page = 1;
    let pageOverridden = false;
    const limit = 12;
    const trainings = ref([]);
    const endOfResults = ref(false);

    const citiesTranslated = computed(() => {
        //If city is not empty, and is included in list of all used locations
        return CitiesDropdown.filter(city => {
            return props.locations.includes(city.value) && city.value !== ''
        }).map(function (city){
            city.label = props.labels[city.label];
            return city;
        });
    });

    onMounted(() => {
        //If page has query parameter, preselect location
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if(params.page) {
            pageOverridden = params.page;
        }
        if(params.location) {
            locationsModel.value = params.location.split('|');
        }

        loadResults();
    });

    function loadMore() {
        if(!loadingMore && !endOfResults.value) {
            loadingMore = true;
            page++;
            loadResults(true);
        }
    }

    function loadResults(more) {
        if(!more) {
            page = 1;
            endOfResults.value = false;
        }
        else {
            //Update query parameters
            const url = new URL(window.location);
            url.searchParams.set('page', page);
            url.search = decodeURIComponent(url.search);
            window.history.replaceState(null, '', url.toString());
        }

        const data = {
            page,
            limit: !pageOverridden ? limit : limit * pageOverridden,
            locations: locationsModel.value,
            site: props.siteHandle,
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/get-trainings', data).then((response) => {
            pageOverridden = false;

            if(more) {
                trainings.value = [...trainings.value, ...response.data];

                setTimeout(() => {
                    loadingMore = false;
                }, 500);
            }
            else {
                trainings.value = response.data;
            }

            if(response.data.length < limit) {
                endOfResults.value = true;
            }

            //Update query parameters
            const url = new URL(window.location.origin + window.location.pathname);
            if(page != 1) {
                url.searchParams.set('page', page);
            }
            if(locationsModel.value.length > 0) {
                url.searchParams.set('location', locationsModel.value.join('|'));
            }
            url.search = decodeURIComponent(url.search);
            window.history.replaceState(null, '', url.toString());
        });
    }
</script>