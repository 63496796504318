export const CitiesDropdown = [
    {
        label: 'Location',
        value: '',
        disabled: 'disabled'
    },
    {
        label: 'Akulivik',
        value: 'akulivik'
    },
    {
        label: 'Aupaluk',
        value: 'aupaluk'
    },
    {
        label: 'Inukjuak',
        value: 'inukjuak'
    },
    {
        label: 'Ivujivik',
        value: 'ivujivik'
    },
    {
        label: 'Kangirsuk',
        value: 'kangirsuk'
    },
    {
        label: 'Kangiqsujuaq',
        value: 'kangiqsujuaq'
    },
    {
        label: 'Kangiqsualujjuaq',
        value: 'kangiqsualujjuaq'
    },
    {
        label: 'Kuujjuaq',
        value: 'kuujjuaq'
    },
    {
        label: 'Kuujjuaraapik',
        value: 'kuujjuaraapik'
    },
    {
        label: 'Laval',
        value: 'laval'
    },
    {
        label: 'Minesite - RAGLAN',
        value: 'minesiteRaglan'
    },
    {
        label: 'Minesite - CRI',
        value: 'minesiteCri'
    },
    {
        label: 'Montreal',
        value: 'montreal'
    },
    {
        label: 'Puvirnituk',
        value: 'puvirnituk'
    },
    {
        label: 'Quaqtaq',
        value: 'quaqtaq'
    },
    {
        label: 'Umiujaq',
        value: 'umiujaq'
    },
    {
        label: 'Salluit',
        value: 'salluit'
    },
    {
        label: 'Sainte-Anne-de-Bellevue',
        value: 'sainteAnneDeBellevue'
    },
    {
        label: 'Tasiujaq',
        value: 'tasiujaq'
    },
    {
        label: 'Other',
        value: 'other'
    },
];