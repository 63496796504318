<template>
    <p v-if="noValidate" class="form-incomplete" @click="noValidate = false">
        <img src="/dist/static/images/svg/warning-triangle.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
        {{ labels['IncompleteProfileError'] }}
    </p>

    <h1 class="title">
        {{ labels['Complete my profile'] }}
    </h1>
    <p class="subtitle">
        {{ labels['CompleteProfileDesc'] }}
    </p>
    <form class="main-content" @submit.prevent="submitForm">
        <h2 class="section">
            {{ labels['Public informations'] }}
        </h2>
        <div class="form-section">
            <h3 class="form-section__title">
                {{ labels['Informations'] }}
            </h3>
            <div class="input-double">
                <CompanyInput id="firstName2" 
                    :labelText="labels['Portal:FirstName']"
                    type="text"
                    v-model="v$.firstName.$model"
                    :error="v$.firstName.$errors.length" />
                <CompanyInput id="lastName2" 
                    :labelText="labels['Portal:LastName']"
                    type="text"
                    v-model="v$.lastName.$model"
                    :error="v$.lastName.$errors.length" />
            </div>
            <div class="input-double">
                <CompanyInput id="contactEmail" 
                    :labelText="labels['Email address*']"
                    type="email"
                    v-model="v$.contactEmail.$model"
                    :error="v$.contactEmail.$errors.length" />
                <CompanyInput id="phone" 
                    :labelText="labels['Phone number*']"
                    type="tel"
                    v-model="v$.phone.$model"
                    :error="v$.phone.$errors.length" />
            </div>
            <div class="input-double">
                <SelectDropdown
                    name="languages" 
                    v-model="v$.langModel.$model"
                    mode="multiple" 
                    :error="v$.langModel.$errors.length"
                    :candeselect="true"
                    :placeholder="labels['Select a spoken language']"
                    :options="languagesList" />
            </div>
            <div class="input-double only-desktop">
                <FileUpload :text="labels['Drag and drop your resume or']"
                    :cta="labels['Select file']"
                    :note="labels['5mb max, pdf only']"
                    :file="resume"
                    type="pdf"
                    id="resume"
                    :title="labels['Resume']"
                    :error="v$.resumeModel.$errors.length"
                    :errorMessage="labels['ResumeUploaderror']"
                    v-model="v$.resumeModel.$model" />
            </div>
        </div>
        <div class="form-section">
            <h3 class="form-section__title">
                {{ labels['Job key words*'] }}
            </h3>
            <SelectDropdown
                name="categories" 
                v-model="v$.categoriesModel.$model"
                mode="multiple" 
                :error="v$.categoriesModel.$errors.length"
                :candeselect="true"
                :placeholder="labels['What type of job are you interested by?']"
                :options="categories" />
            <p class="fake-checkbox" :class="{'is-selected': wantsAlert}" @click="wantsAlert = !wantsAlert">
                <span class="fake-checkbox__checkbox" />{{ labels['NotificationsAuthorization'] }}
            </p>
        </div>
        <div class="form-section">
            <h3 class="form-section__title">
                {{ labels['Location*'] }}
            </h3>
            <div class="input-double">
                <CompanyInput id="location" 
                    :labelText="labels['In what location do you want to work?']"
                    :placeholderText="labels['Location']"
                    type="select"
                    v-model="v$.location.$model"
                    :error="v$.location.$errors.length"
                    :options="citiesTranslated" />
            </div>
        </div>
        <h2 class="section">
            {{ labels['Account'] }}
        </h2>
        <div class="form-section">
            <h3 class="form-section__title">
                {{ labels['Personal informations'] }}
            </h3>
            <div class="input-double">
                <CompanyInput id="email2"
                    type="email"
                    :labelText="labels['Email address*']"
                    :modelValue="accountEmailCopy"
                    className="is-editable"
                    :readonly="true"
                    @click="isPopupEmailOpen = true" />
                <CompanyInput id="password3" 
                    type="password"
                    :labelText="labels['Password*']"
                    placeholderText="***********"
                    className="is-editable"
                    :readonly="true"
                    @click="isPopupPasswordOpen = true" />
            </div>
        </div>
        <div class="form-row is-double is-submit">
            <Button :text="labels['Save modifications']" @click="submitForm" />
        </div>
    </form>
    <Teleport to="body">
        <Transition>
            <Popup v-if="isPopupEmailOpen"
                :title="labels['Edit your Email']"
                className="is-open"
                @close="isPopupEmailOpen = false">
                <form v-if="!emailSubmitted" @submit.prevent="changeEmail">
                    <p v-html="labels['EditEmailTxt']" class="note" />
                    <CompanyInput id="currentPassword" 
                        :labelText="labels['Current password*']"
                        type="password"
                        v-model="v$Email.currentPassword.$model"
                        :error="v$Email.currentPassword.$errors.length" />
                    <CompanyInput id="newEmail1" 
                        :labelText="labels['New email address*']"
                        type="email"
                        v-model="v$Email.newEmail1.$model"
                        :error="v$Email.newEmail1.$errors.length" />
                    <CompanyInput id="newEmail2" 
                        :labelText="labels['Confirmation new email address*']"
                        type="email"
                        v-model="v$Email.newEmail2.$model"
                        :error="v$Email.newEmail2.$errors.length" />
                    <div class="form-row is-error" v-if="errorMessage">
                        {{ errorMessage }}
                    </div>
                    <Button :text="labels['Confirm this edit']" @click="changeEmail" />
                </form>
                <p v-else v-html="labels['EditEmailTxtSubmit']" />
            </Popup>
        </Transition>
        <Transition>
            <Popup v-if="isPopupPasswordOpen"
                :title="labels['Change your Password']"
                className="is-open"
                @close="isPopupPasswordOpen = false">
                <form v-if="!passwordChanged" @submit.prevent="changePassword">
                    <CompanyInput id="currentPassword" 
                        :labelText="labels['Current password*']"
                        type="password"
                        v-model="v$Password.currentPassword.$model"
                        :error="v$Password.currentPassword.$errors.length" />
                    <p class="note" v-html="labels['Portal:Password Check']" />
                    <CompanyInput id="newPassword1" 
                        :labelText="labels['New password*']"
                        type="password"
                        v-model="v$Password.newPassword1.$model"
                        :error="v$Password.newPassword1.$errors.length" />
                    <CompanyInput id="newPassword2" 
                        :labelText="labels['New password confirmation*']"
                        type="password"
                        v-model="v$Password.newPassword2.$model"
                        :error="v$Password.newPassword2.$errors.length" />
                    <div class="form-row is-error" v-if="errorMessage">
                        {{ errorMessage }}
                    </div>
                    <Button :text="labels['Confirm this edit']" @click="changePassword" />
                </form>
                <p v-else v-html="labels['ChangePwdTxtSubmit']" />
            </Popup>
        </Transition>
        <Transition>
            <Notification v-if="notifSuccessOpen" 
                :title="labels['ProfileSavecSucess']"
                @close="notifSuccessOpen = false" />
        </Transition>
        <Transition>
            <Notification v-if="notifErrorOpen" 
                :title="labels['SaveError']" 
                type="error"
                @close="notifErrorOpen = false" />
        </Transition>
    </Teleport>
</template>

<script setup>
    import { computed, reactive, ref, nextTick, watch } from 'vue';
    import useVuelidate from '@vuelidate/core';
    import { required, helpers, alpha, email, sameAs, minLength } from '@vuelidate/validators';
    import axios from 'axios';
    import qs from 'qs';
    import { CitiesDropdown } from '../components/CitiesDropdown.js';
    import { FieldsOfActivityDropdown } from '../components/FieldsOfActivityDropdown.js';
    import FileUpload from '../components/FileUpload.vue';
    import CompanyInput from './CompanyInput.vue';
    import SelectDropdown from './SelectDropdown.vue';
    import Popup from './Popup.vue';
    import Notification from './Notification.vue';
    import Button from './Button.vue';

    const props = defineProps([
        'labels',
        'userId',
        'firstName',
        'lastName',
        'contactEmail',
        'phoneNumber',
        'spokenLanguages',
        'resume',
        'resumeScreenshot',
        'userCategories',
        'categories',
        'location',
        'wantsAlert',
        'accountEmail',
        'siteHandle',
    ]);

    const errorMessage = ref('');
    const isPopupApprovalOpen = ref(false);
    const isPopupEmailOpen = ref(false);
    const isPopupPasswordOpen = ref(false);
    const notifSuccessOpen = ref(false);
    const notifErrorOpen = ref(false);
    const emailSubmitted = ref(false);
    const passwordChanged = ref(false);

    const citiesTranslated = computed(() => {
        return CitiesDropdown.map(function (city){
            city.label = props.labels[city.label];
            return city;
        });
    });
    const languagesList = [
        {
            value: 'en',
            label: props.labels['English']
        },
        {
            value: 'fr',
            label: props.labels['French']
        },
        {
            value: 'iu',
            label: props.labels['ᐃᓄᒃᑎᑐᑦ']
        },
    ];

    //Form and validation stuff
    const firstName = ref(props.firstName);
    const lastName = ref(props.lastName);
    const contactEmail = ref(props.contactEmail || props.accountEmail);
    const phone = ref(props.phoneNumber);
    const langModel = ref(props.spokenLanguages);
    const categoriesModel = ref(props.userCategories);
    const location = ref(props.location);
    const resumeModel = ref({});
    const wantsAlert = ref(props.wantsAlert);
    
    const accountEmailCopy = ref(props.accountEmail);
    const currentPassword = ref('');
    const newEmail1 = ref('');
    const newEmail2 = ref('');
    const newPassword1 = ref('');
    const newPassword2 = ref('');

    //Validations for main form
    const isPdf = (value) => !helpers.req(value) || (value.type.indexOf('pdf') > -1 && value.size < 5000000);
    const isPhone = (value) => helpers.regex(/^\(\d{3}\) \d{3}-\d{4}/);
    const rules = computed(() => {
        return {
            firstName: {
                required, alpha
            },
            lastName: {
                required, alpha
            },
            contactEmail: {
                required, email
            },
            phone: {
                required, isPhone, minLength: minLength(14)
            },
            langModel: {
                required
            },
            location: {
                required,
            },
            resumeModel: {
                isPdf
            },
            categoriesModel: {
                required
            }
        }
    });
    const v$ = useVuelidate(rules, {firstName, lastName, contactEmail, phone, langModel, location, resumeModel, categoriesModel}, {
        $autoDirty: true,
        $lazy: true
    });

    //Validations for email change form
    const rulesEmail = computed(() => {
        return {
            currentPassword: {
                required
            },
            newEmail1: {
                required, email
            },
            newEmail2: {
                required, email, sameAs: sameAs(newEmail1)
            },
        }
    });
    const v$Email = useVuelidate(rulesEmail, {currentPassword, newEmail1, newEmail2}, {
        $autoDirty: true,
        $lazy: true
    });

    //Validations for password change form
    const passwordValidator = helpers.regex(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Z])(?=.*[!@?\/\\\.#$%\-_=+<>&*()|¯´~{\[\]\}]).{8,50}/);
    const rulesPassword = computed(() => {
        return {
            currentPassword: {
                required
            },
            newPassword1: {
                required, passwordValidator,
            },
            newPassword2: {
                required, sameAs: sameAs(newPassword1)
            },
        }
    });
    const v$Password = useVuelidate(rulesPassword, {currentPassword, newPassword1, newPassword2}, {
        $autoDirty: true,
        $lazy: true
    });

    async function changeEmail() {
        v$Email.value.$validate();
        
        if (!v$Email.value.$error) {
            const data = {
                action: 'users/save-user',
                userId: props.userId,
                email: newEmail1.value,
                password: currentPassword.value,
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            axios.post('/', qs.stringify(data)).then((response) => {
                if(!response.data.errors) {
                    errorMessage.value = '';
                    notifSuccessOpen.value = true;
                    emailSubmitted.value = true;
                }
                else {
                    notifErrorOpen.value = true;

                    errorMessage.value = '';
                    for (const property in response.data.errors) {
                        errorMessage.value += response.data.errors[property] + ' ';
                    }
                }
                
            }).catch((error) => {
                errorMessage.value = error;
                notifErrorOpen.value = true;
            });
        }
        else {
            notifErrorOpen.value = true;
        }
    }

    function changePassword() {
        v$Password.value.$validate();
        
        if (!v$Password.value.$error) {
            const data = {
                action: 'users/save-user',
                userId: props.userId,
                password: currentPassword.value,
                newPassword: newPassword1.value,
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            axios.post('/', qs.stringify(data)).then((response) => {
                if(!response.data.errors) {
                    errorMessage.value = '';
                    notifSuccessOpen.value = true;
                    passwordChanged.value = true;
                }
                else {
                    notifErrorOpen.value = true;

                    errorMessage.value = '';
                    for (const property in response.data.errors) {
                        errorMessage.value += response.data.errors[property] + ' ';
                    }
                }
                
            }).catch((error) => {
                errorMessage.value = error;
                notifErrorOpen.value = true;
            });
        }
        else {
            notifErrorOpen.value = true;
        }
    }

    const noValidate = ref(false);
    async function submitForm() {
        v$.value.$validate();

        if (!v$.value.$error) {
            noValidate.value = false;
            const data = {
                action: 'users/save-user',
                userId: props.userId,
                firstName: firstName.value,
                lastName: lastName.value,
                fields: {
                    contactEmail: contactEmail.value,
                    phoneNumber: phone.value,
                    spokenLanguages: langModel.value,
                    location: location.value,
                    jobCategories: categoriesModel.value,
                    wantsalert: +wantsAlert.value,
                }
            };
            data[window.csrfTokenName] = window.csrfTokenValue;

            if(resumeModel.value?.size) {
                //Need to create new asset or replace an existing one. Works this way because stuff is complicated
                const assetData = new FormData();
                assetData.append(window.csrfTokenName, window.csrfTokenValue);
                //Overwrite existing resume if already exists
                if(props.resume?.id) {
                    assetData.append('replaceFile', resumeModel.value, `CV-${props.firstName}_${props.lastName}.pdf`);
                    assetData.append('action', 'assets/replace-file');
                    assetData.append('targetFilename', `CV-${props.firstName}_${props.lastName}.pdf`);
                    assetData.append('assetId', props.resume.id);
                }
                else {
                    assetData.append('assets-upload', resumeModel.value, `CV-${props.firstName}_${props.lastName}.pdf`);
                    assetData.append('action', 'assets/upload');
                    assetData.append('folderId', 4);
                }
                axios.post('/', assetData).then((response) => {
                    data.fields.resume = [response.data.assetId];
                    submitForm2(data);
                }).catch((error) => {
                    errorMessage.value = error;
                    notifErrorOpen.value = true;
                });
            }
            //We are deleting an existing file
            else if(resumeModel.value == null && props.resume?.id) {
                const assetData = new FormData();
                assetData.append(window.csrfTokenName, window.csrfTokenValue);
                assetData.append('action', 'assets/delete-asset');
                assetData.append('assetId', props.resume.id);

                axios.post('/', assetData).then((response) => {
                    submitForm2(data);
                }).catch((error) => {
                    errorMessage.value = error;
                    notifErrorOpen.value = true;
                });
            }
            else {
                submitForm2(data);
            }
        }
        else {
            notifErrorOpen.value = true;
            noValidate.value = true;

            nextTick(() => {	
                document.querySelector('.form-incomplete').scrollIntoView();
            });
        }
    }

    async function submitForm2(data) {
        await axios.post('/', qs.stringify(data)).then(async () => {
            errorMessage.value = '';
            notifSuccessOpen.value = true;

            //Stuff to generate image from uploaded pdf (not working currently)
            /*if(data.fields.resume) {
                await axios.get('/get-resume-link').then(async (response) => {
                    await axios.get(`/generate-resume-image?siteHandle=${props.siteHandle}&file=${response.data}`).then(async (response) => {
                        //Now we check, generate & submit the resume image
                        const assetData = new FormData();
                        assetData.append(window.csrfTokenName, window.csrfTokenValue);

                        //First we need to convert base64-encoded resume to a blob
                        const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
                            const byteCharacters = atob(b64Data);
                            const byteArrays = [];

                            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                                const slice = byteCharacters.slice(offset, offset + sliceSize);

                                const byteNumbers = new Array(slice.length);
                                for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                                }

                                const byteArray = new Uint8Array(byteNumbers);
                                byteArrays.push(byteArray);
                            }

                            const blob = new Blob(byteArrays, {type: contentType});
                            return blob;
                        }
                        const blob = b64toBlob(response.data, 'image/png');

                        //Overwrite existing resume if already exists
                        if(props.resumeScreenshot?.id) {
                            assetData.append('replaceFile', blob, `CV-${props.firstName}_${props.lastName}.png`);
                            assetData.append('action', 'assets/replace-file');
                            assetData.append('targetFilename', `CV-${props.firstName}_${props.lastName}.png`);
                            assetData.append('assetId', props.resumeScreenshot?.id);
                        }
                        else {
                            assetData.append('assets-upload', blob, `CV-${props.firstName}_${props.lastName}.png`);
                            assetData.append('action', 'assets/upload');
                            assetData.append('folderId', 4);
                        }
                        axios.post('/', assetData).then(async (response) => {
                            //Resume is uploaded, then save it to profile
                            const data = {
                                action: 'users/save-user',
                                userId: props.userId,
                                fields: {
                                    resumeScreenshot: [response.data.assetId],
                                }
                            };
                            data[window.csrfTokenName] = window.csrfTokenValue;

                            await axios.post('/', qs.stringify(data)).then(async (response) => {
                                
                            });
                        });
                    });
                });
            }*/
        }).catch((error) => {
            errorMessage.value = error;
            notifErrorOpen.value = true;
        });
    }
</script>