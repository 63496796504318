<template>
    <h1 class="title">
        {{ labels['My job offers'] }}
        <a class="button" :href="labels['/portal/employer/job']">
            <span class="plus" />
            {{ labels['Create a job offer'] }}
        </a>
    </h1>
    <div class="filters">
        <div class="filters__inner">
            <div class="filter" @click="selectedFilter = 'all'" :class="{'is-selected': selectedFilter == 'all'}">
                {{ labels['All offers'] }} ({{ jobsCounts?.active + jobsCounts?.archived }})
            </div>
            <div class="filter" @click="selectedFilter = 'active'" :class="{'is-selected': selectedFilter == 'active'}">
                {{ labels['Active'] }} ({{ jobsCounts?.active }})
            </div>
            <div class="filter" @click="selectedFilter = 'pending'" :class="{'is-selected': selectedFilter == 'pending'}">
                {{ labels['Pending'] }} ({{ jobsCounts?.pending }})
            </div>
            <div class="filter" @click="selectedFilter = 'archived'" :class="{'is-selected': selectedFilter == 'archived'}">
                {{ labels['Archived'] }} ({{ jobsCounts?.archived }})
            </div>
        </div>
    </div>
    <table class="table">
        <thead v-if="jobs.length > 0">
            <tr>
                <th :class="['selectable', sorterDirection, {'is-selected': selectedSorter == 'title'}]" @click="sort('title')">
                    {{ labels['Job name'] }}
                </th>
                <th :class="['selectable', sorterDirection, {'is-selected': selectedSorter == 'postDate'}]" @click="sort('postDate')">
                    {{ labels['Publication date'] }}
                </th>
                <th :class="['selectable', sorterDirection, {'is-selected': selectedSorter == 'views'}]" @click="sort('views')">
                    {{ labels['Views'] }}
                </th>
                <th>
                    {{ labels['Actions'] }}
                </th>
            </tr>
        </thead>
        <tbody :class="{'no-jobs': jobs.length < 1}">
            <tr v-for="job in jobs" :key="job.id">
                <td>
                    <a :href="labels['/portal/employer/job'] + '?id=' + job.id">
                        {{ job.title }}
                    </a>
                </td>
                <td>
                    {{ formatDate(job.date.date, props.currentLang) }}
                </td>
                <td>
                    {{ job.views }} {{ labels[job.views > 1 ? 'views' : 'view'] }}
                </td>
                <td>
                    <div class="actions"
                        :class="{'is-opened': openedAction == job.id}"
                        @click="openedAction = openedAction == job.id ? '' : job.id;">
                        <img src="/dist/static/images/svg/settings.svg" alt="" fetchpriority="low" decoding="async" loading="lazy" />
                        <ul v-if="openedAction == job.id">
                            <li @click="editJob(job.id)">
                                {{ labels['Edit'] }}
                            </li>
                            <li @click="deletingJob = job.id">
                                {{ labels['Delete'] }}
                            </li>
                            <li v-if="!job.isArchived" @click="archiveJob(job.id)">
                                {{ labels['Archive'] }}
                            </li>
                            <li v-else @click="activateJob(job.id)">
                                {{ labels['Activate'] }}
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <tr v-if="jobs.length < 1">
                <td colspan="4" v-html="labels['You don\'t have any job offers at the moment']" />
            </tr>
        </tbody>
    </table>
    <div class="pager" v-if="jobs.length > 0">
        <span class="prev" 
            v-html="labels['Prev.']"
            @click="page--"
            :class="{'is-disabled': page == 1}" />
        <span class="page" 
            v-for="pageElem in nbPages" 
            :key="pageElem" 
            :class="{'is-selected': pageElem == page}" 
            @click="page = pageElem"
            v-html="pageElem" />
        <span class="next" 
            v-html="labels['Next']"
            @click="page++"
            :class="{'is-disabled': page == nbPages || !nbPages}"/>
    </div>
    <Teleport to="body">
        <Transition>
            <Popup v-if="deletingJob != ''"
                :title="labels['Are you sure you want to delete this job offer?']"
                @close="deletingJob = ''">
                <div class="buttons">
                    <button v-html="labels['No']" 
                        @click="deletingJob = ''"
                        className="secondary" />
                    <button v-html="labels['Yes']" @click="deleteJob" />
                </div>
            </Popup>
        </Transition>
        <Transition>
            <Notification v-if="notifSuccess != ''" 
                :title="notifSuccess"
                @close="notifSuccess = ''" />
        </Transition>
        <Transition>
            <Notification v-if="notifError != ''" 
                :title="notifError" 
                type="error"
                @close="notifError = ''" />
        </Transition>
    </Teleport>
</template>

<script setup>
    import { computed, ref, watch, onMounted, inject } from 'vue';
    import axios from 'axios';
    import qs from 'qs';
    import Popup from './Popup.vue';
    import Notification from './Notification.vue';

    const props = defineProps([
        'labels',
        'currentLang',
    ]);

    const jobs = ref([]);
    const formatDate = inject('formatDate');
    const getSiteId = inject('getSiteId');

    //Used to sort/filter entries
    const selectedFilter = ref('all');
    const selectedSorter = ref('postDate');
    const sorterDirection = ref('DESC');
    function sort(sorter) {
        if(selectedSorter.value == sorter) {
            sorterDirection.value = sorterDirection.value == 'ASC' ? 'DESC' : 'ASC';
        }
        else {
            selectedSorter.value = sorter;
        }
    }

    //Pager-related stuff
    const limit = ref(8);
    const page = ref(1);
    const nbPages = computed(() => {
        let totalResults = 0;

        if(selectedFilter.value == 'all') {
            totalResults = jobsCounts.value?.active + jobsCounts.value?.archived;
        }
        else if(selectedFilter.value == 'active') {
            totalResults = jobsCounts.value?.active;
        }
        else if(selectedFilter.value == 'pending') {
            totalResults = jobsCounts.value?.pending;
        }
        else if(selectedFilter.value == 'archived') {
            totalResults = jobsCounts.value?.archived;
        }

        const nbPagesFinal = Math.ceil(totalResults / limit.value);
        return isNaN(nbPagesFinal) ? 1 : nbPagesFinal;
    });

    //Get numbers of jobs in each category
    const jobsCounts = ref();
    function getCounts() {
        const data = {
            site: props.currentLang,
        };
        data[window.csrfTokenName] = window.csrfTokenValue;
        
        axios.post('/get-business-jobs-counts', data).then((response) => {
            jobsCounts.value = response.data;
        });
    }

    //Load results on mounted, or when any filter/selector/page changes
    onMounted(() => {
        loadResults();
        getCounts();
    });
    watch([selectedFilter, selectedSorter, sorterDirection, page], () => {
        loadResults();
    });

    function loadResults(more) {
        const data = {
            page: page.value,
            limit: limit.value,
            filter: selectedFilter.value,
            site: props.currentLang,
            sort: selectedSorter.value,
            sortDirection: sorterDirection.value,
            lang: props.currentLang
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/get-business-jobs', data).then((response) => {
            jobs.value = response.data;
        });
    }

    const openedAction = ref('');

    const notifSuccess = ref('');
    const notifError = ref('');
    const deletingJob = ref('');

    function editJob(id) {
        window.location.href = props.labels['/portal/employer/job'] + '?id=' + id;
    }

    function deleteJob() {
        const data = {
            action: 'entries/delete-entry',
            siteId: getSiteId(props.currentLang),
            sourceId: deletingJob.value
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/', qs.stringify(data)).then((response) => {
            if(!response.data.errors) {
                notifSuccess.value = props.labels['Job offer deleted'];
                loadResults();
                getCounts();
            }
            else {
                notifError.value = props.labels['DeleteError'];
            }

            deletingJob.value = '';
            
        }).catch((error) => {
            notifError.value = props.labels['DeleteError'];
            deletingJob.value = '';
        });
    }

    function archiveJob(id) {
        const data = {
            action: 'entries/save-entry',
            siteId: getSiteId(props.currentLang),
            sourceId: id,
            fields: {
                entryArchive: 1
            }
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/', qs.stringify(data)).then((response) => {
            if(!response.data.errors) {
                notifSuccess.value = props.labels['Job offer archived'];
                loadResults();
                getCounts();
            }
            else {
                notifError.value = props.labels['SaveError'];
            }
            
        }).catch((error) => {
            notifError.value = props.labels['SaveError'];
        });
    }

    function activateJob(id) {
        const data = {
            action: 'entries/save-entry',
            siteId: getSiteId(props.currentLang),
            sourceId: id,
            fields: {
                entryArchive: 0
            }
        };
        data[window.csrfTokenName] = window.csrfTokenValue;

        axios.post('/', qs.stringify(data)).then((response) => {
            if(!response.data.errors) {
                notifSuccess.value = props.labels['Job offer activated'];
                loadResults();
                getCounts();
            }
            else {
                notifError.value = props.labels['SaveError'];
            }
            
        }).catch((error) => {
            notifError.value = props.labels['SaveError'];
        });
    }
</script>