<template>
    <div class="file-upload" :class="[{'is-error': error}]">
        <div class="file-upload__remove" v-if="(file || newFile) && !fileDeleted && canRemove" @click="handleFileRemove" />
        <img v-if="type == 'img' && ((file && !fileDeleted) || newFile) && !error" :src="newFile || file" 
            alt="" 
            class="file-upload__preview"
            fetchpriority="low" decoding="async" loading="lazy">
        <component v-else-if="type == 'pdf' && ((file && !fileDeleted) || newFile)" 
            v-html="newFileName || file.name"
            :is="!newFileName && file ? 'a' : 'span'"
            target="_blank"
            :href="file?.url"
            class="file-upload__name" />
            <span v-else-if="title"
                v-html="title"
                class="file-upload__name" />
        <label class="file-upload__zone">
            <template v-if="!newFileName || error || type == 'pdf'">
                {{ error ? errorMessage : text }}
                <div class="file-upload__cta">
                    {{ cta }}
                </div>
                {{ note }}
            </template>
            <template v-else-if="!error">
                {{ newFileName }}
            </template>
            <input type="file" 
                :id="id"
                @change="handleFileUpload($event)" />
        </label>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        id: String,
        text: String,
        note: String,
        cta: String,
        file: String,
        type: {
            type: String,
            default: 'img'
        },
        error: Number,
        errorMessage: String,
        title: String,
        canRemove: {
            type: Boolean,
            default: true,
        },
    });
    const newFile = ref('');
    const newFileName = ref('');
    const fileDeleted = ref(false);

    const emit = defineEmits(['update:modelValue']);
    function handleFileUpload(event) {
        newFile.value = URL.createObjectURL(event.target.files[0]);
        newFileName.value = event.target.files[0].name;
        fileDeleted.value = false;
        emit('update:modelValue', event.target.files[0]);
    }
    function handleFileRemove() {
        newFile.value = '';
        newFileName.value = '';
        fileDeleted.value = true;
        emit('update:modelValue', null);
    }
</script>
